
import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row,
  Label
} from "reactstrap";
import React, { 
  useState, 
  useEffect, 
} from "react";
import {
  AvForm,
  AvField,
  AvInput
} from "availity-reactstrap-validation";
import { addNewLeadExcel } from "../../store/leads/actions";
import { withTranslation } from "react-i18next";
import SourceDropDown from "../../components/Common/SourceDropDown";
import SearchableAgentDropdown from "components/Common/SearchableAgentDropdown";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";

function ImportExcel(props) {
  const dispatch = useDispatch();
  const [file, setFile] = useState({});
  const [addModal, setAddUserModal] = useState(false);
  const [hasNotes, setHasNotes] = useState(false);
  const [useStatus, setUseStatus] = useState(false);
  const [noteHeaders, setNoteHeaders] = useState([]);
  const [source, setSource] = useState("");
  const { create } = props.leadsPermissions;

  const handleAddLead = (event, values)=>{
    event.preventDefault();
    const formData = new FormData();
    formData.append("leads", file);
    formData.append("source", source);
    formData.append("agent", values.agent);
    if (hasNotes) formData.append("noteHeaders", noteHeaders);
    formData.append("useStatus", useStatus);
    dispatch(addNewLeadExcel(formData));
  }; 

  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };

  useEffect(() => {
    if (props.clearingCounter > 0 && addModal){
      setAddUserModal(false);
    }
  }, [props.clearingCounter]);
  
  const validateFile = (value, ctx, input, cb)=> {
    const extensions = ["csv", "xls", "xlsx"];
    const extension = value.split(".")[1];
    if (extensions.includes(extension) || !value){
      if (!value || file.size <= 2097152){
        cb(true);
      } else cb("2mb maximum size");
    } else cb("Only images or PDF can be uploaded");   
  };
  
  return (
    <React.Fragment>
      <Button
        color="primary"
        className={`btn btn-primary ${!create ? "d-none" : ""}`}
        onClick={toggleAddModal}>
        <i className="bx bx-plus me-1"/> 
        {props.t("Import Leads from Excel")}
      </Button>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Import Excel")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleAddLead(e, v);
            }}
          >
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <AvField
                    name="lead"
                    type="file"
                    label={props.t("Select File")}
                    errorMessage={props.t("Please Select a valid file")}
                    validate = {{
                      required:{ value: true },
                      custom: validateFile 
                    }}
                    onChange={(e)=> setFile(e.target.files[0])}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <SourceDropDown 
                    setSource = {setSource}
                  />
                </div>
                <AvField
                  type="text"
                  name="source"
                  value={source}
                  errorMessage={props.t("Please Select a source")}
                  validate = {{
                    required:{ value: true },
                  }}
                  style={{
                    margin: -15,
                    opacity: 0, 
                    height: 0,
                    width: 0
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <SearchableAgentDropdown
                  label={props.t("Agent")}
                  name="agent"
                  errorMessage={props.t("Please Select an agent")}
                  validate = {{
                    required:{ value: true },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <AvInput
                    type="checkbox" 
                    name="uploadNotes"
                    defaultChecked={hasNotes}
                    value={hasNotes}
                    onChange={(e)=> setHasNotes(e.target.checked)}
                  /> {props.t("Do you have notes columns?")}
                </div>
              </Col>
              {
                hasNotes && (
                  <Col md="12">
                    <Label>{props.t("Notes Headers")}</Label>
                    <div className="mb-3">
                      <CustomCreatableSelect
                        label={props.t("Notes")}
                        name="headers"
                        isMulti
                        value={noteHeaders.map((item) => ({
                          label: item,
                          value: item,
                        }))
                        }
                        dispatchState={(e) => setNoteHeaders(e)}
                        placeholder={props.t("Enter Notes Headers")}
                        isRequired={true}
                      />
                    </div>
                  </Col>
                )
              }
              <Col md="12">
                <div className="mb-3">
                  <AvInput
                    type="checkbox" 
                    name="useDate"
                    defaultChecked={hasNotes}
                    value={hasNotes}
                    onChange={(e)=> setUseStatus(e.target.checked)}
                  /> {props.t("Use Status in Excel?")}
                </div>
              </Col>
            </Row>
            <div className='text-center pt-3 p-2'>
              <Button disabled={
                props.disableAddButton || (hasNotes && !noteHeaders.length)
              } type="submit" color="primary" className="">
                {props.disableAddButton ? ( <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"/>) : props.t("Add")}
              </Button>
            </div>
          </AvForm>
          {
            props.error && (
              <UncontrolledAlert color="danger">
                <i className="mdi mdi-block-helper me-2"/>
                {props.t(props.error)}
              </UncontrolledAlert>
            )
          }
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  error: state.leadReducer.error,
  showAddSuccessMessage: state.leadReducer.showAddSuccessMessage,
  disableAddButton: state.leadReducer.excelLoading,
  leadsPermissions: state.Profile.leadsPermissions || {},
  clearingCounter: state.leadReducer.addClearingCounter,
});

export default connect(mapStateToProps, null)(withTranslation()(ImportExcel));