import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
} from "reactstrap";
// import "../SearchableInputStyles.scss";
import { withTranslation } from "react-i18next";
import { loadFacebookCampaignsStart } from "store/actions";
import TableLoader from "components/Common/Loader";

function FetchCampaigns(props){
  const dispatch = useDispatch();
  const {
    facebookLoading,
  } = useSelector((state)=>({
    facebookLoading: state.facebookCampaignReducer.facebookLoading || false
  }));

  return (
    <React.Fragment >
      <Button
        color="primary"
        className={"btn btn-primary "}
        disabled={facebookLoading}
        onClick={() => dispatch(loadFacebookCampaignsStart())}>
        {
          facebookLoading ? (
            <TableLoader />
          ) : <>
            <i className="bx bx-right-arrow me-1"></i>
            {props.t("Load Campaigns from Facebook")}
          </>
        }
      </Button>
    </React.Fragment>
  );
}

export default (withTranslation()(FetchCampaigns));