import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import {
  Card, CardBody, CardHeader, CardTitle
} from "reactstrap";
import TableLoader from "components/Common/Loader";
import {
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Table,
} from "react-super-responsive-table";
import CustomPagination from "components/Common/CustomPagination";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import EditLeadStructure from "./EditProject";
import FeatherIcon from "feather-icons-react";
import usePermissions from "routes/permissions";
import AddProject from "./AddProject";
import { fetchProject } from "store/actions";

function Project() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sizePerPage, setSizePerPage] = useState(10);
  const [project, setProject] = useState(null);

  const {
    clearingCounter
  } = useSelector((state)=>({
    clearingCounter: state.projectReducer.clearingCounter || 0
  }));

  const {
    projectPermissions
  } = usePermissions();
  const {
    loading,
    docs,
    pagination,
  } = useSelector((state) => ({
    loading: state.projectReducer.loading || false,
    docs: state.projectReducer.docs || [],
    pagination: state.projectReducer.pagination || {},
  }));

  const columns = [
    {
      text: "#",
      dataField: "recordId",
      sort: true,
      formatter: (val) => (
        <>
          {val?.recordId}
        </>
      ),
    },
    {
      text: "Project  Name",
      dataField: "name",
    },
    {
      text: "Size",
      dataField: "size",
    },
    {
      text: "Developer  Name",
      dataField: "developerName",
      formatter: (val) => (
        <>
          {val?.developer?.name}
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (project) => (
        <div className="text-center cursor-pointer">
          <div className={`text-success ${!parseInt(1) ? "d-none" : ""}`} to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => setProject(project)}
            ></i>
          </div>
        </div>
      ),
    },
  ];

  const loadProjects = (page, limit) => {
    dispatch(fetchProject({
      page,
      limit,
    }));
  };
  
  useEffect(() => {
    loadProjects(1, sizePerPage);
  }, [clearingCounter]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("Project List")}</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>{t("Project List")}</h2>
          <Card>
            <CardHeader className="d-flex justify-content-between  align-items-center">
              <CardTitle>
                Projects ({pagination?.totalDocs || 0})
                <FeatherIcon
                  icon="refresh-cw"
                  className="icon-lg ms-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => { loadProjects(1, sizePerPage) }}
                />
              </CardTitle>
              { projectPermissions.create && <>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <AddProject />
                </div>
              </>}
            </CardHeader>
            {
              projectPermissions.update && project && (
                <EditLeadStructure t={t} toggle={() => setProject(null)} project={project} />
              )
            }
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table  table-hover "
                  >
                    <Thead className="text-center table-light" >
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}>{column.text}</Th>
                        )}
                      </Tr>
                    </Thead>
                    <Tbody className="text-center">
                      {loading && <TableLoader colSpan={6} />}
                      {!loading && docs.map((row, rowIndex) =>
                        <Tr key={rowIndex}>
                          {columns.map((column, index) =>
                            <Td key={`${rowIndex}-${index}`}>
                              {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                            </Td>
                          )}
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                  <CustomPagination
                    docs={docs}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    onChange={loadProjects}
                    {...pagination}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Project;
