import { updateLeadType } from "apis/client";
import { AvForm } from "availity-reactstrap-validation";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";
import AvFieldSelect from "components/Common/AvFieldSelect";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import {
  Button,
  Col,
  Label,
  Row,
  Spinner
} from "reactstrap";
import { showErrorNotification, showSuccessNotification } from "store/actions";

const LEAD_TYPES = [
  "INVESTOR",
  "END_USER",
  "PARTNER"
];

export default function LeadType({
  value = "", clientId, update
}) {

  const [loading, setLoading] = useState(false);
  const [leadType, setLeadType] = useState(value);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      leadType,
      clientId,
    };
    updateLeadType(payload).then(res => {
      dispatch(showSuccessNotification("Updated Successfully"));
    }).catch(err => {
      console.log(err);
      dispatch(showErrorNotification("Failed to update"));
    }).finally(()=>{
      setLoading(false);
    });
  };

  return (
    <Row>
      <Col md={9}>
        <Label>Lead Type</Label>
        <ReactSelect
          name="leadType"
          label="Lead Type"
          placeholder="Select Lead Type"
          value={{
            label: leadType,
            value: leadType,
          }}
          defaultValue={{
            label: leadType,
            value: leadType,
          }}
          options={LEAD_TYPES.map((type) => ({
            value: type,
            label: type
          }))}
          onChange={(e)=> {
            setLeadType(e.value);
          }}
          isDisabled={!update}
        />
      </Col>
      <Col md={3}>
        {update && <div className="text-center">
          {
            loading ? <Spinner /> : <Button
              className="mt-4"
              color="primary"
              onClick={handleSubmit}
            >{t("Update")}</Button>
          }
        </div>}
      </Col>
    </Row>
  );
}
