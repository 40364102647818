export const FETCH_CLIENTS_START = "FETCH_CLIENTS_START";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_FAILED = "FETCH_CLIENTS_FAILED";

export const FETCH_REFERRALS_START = "FETCH_REFERRALS_START";
export const FETCH_REFERRALS_SUCCESS = "FETCH_REFERRALS_SUCCESS";

export const FETCH_IB_PARENTS_START = "FETCH_IB_PARENTS_START";
export const FETCH_IB_PARENTS_SUCCESS = "FETCH_IB_PARENTS_SUCCESS";

export const FETCH_STATEMENT_START = "FETCH_STATEMENT_START";
export const FETCH_STATEMENT_SUCCESS = "FETCH_STATEMENT_SUCCESS";
export const FETCH_STATEMENT_DEALS_START = "FETCH_STATEMENT_DEALS_START";
export const FETCH_STATEMENT_DEALS_SUCCESS = "FETCH_STATEMENT_DEALS_SUCCESS";

export const LINK_CLIENT_START = "LINK_CLIENT_START";
export const LINK_CLIENT_SUCCESS = "LINK_CLIENT_SUCCESS";
export const UNLINK_IB_START = "UNLINK_IB_START";
export const UNLINK_IB_SUCCESS = "UNLINK_IB_SUCCESS";
export const UNLINK_CLIENTS_START = "UNLINK_CLIENTS_START";
export const UNLINK_CLIENTS_SUCCESS = "UNLINK_CLIENTS_SUCCESS";

export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const ADD_NEW_CLIENT_SUCCESS = "ADD_NEW_CLIENT_SUCCESS";
export const ADD_NEW_IB = "ADD_NEW_IB";
export const ADD_NEW_IB_SUCCESS = "ADD_NEW_IB_SUCCESS";
export const API_ERROR = "API_ERROR";

// fetch client details
export const FETCH_CLIENT_DETAILS_REQUESTED = "FETCH_CLIENT_DETAILS_REQUESTED";
export const FETCH_CLIENT_DETAILS_SUCCESS = "FETCH_CLIENT_DETAILS_SUCCESS";
export const FETCH_CLIENT_DETAILS_FAIL = "FETCH_CLIENT_DETAILS_FAIL";
export const FETCH_CLIENT_DETAILS_CLEAR = "FETCH_CLIENT_DETAILS_CLEAR";

export const FETCH_CLIENT_STAGES_START = "FETCH_CLIENT_STAGES_START";
export const FETCH_CLIENT_STAGES_END = "FETCH_CLIENT_STAGES_END";

// update client details 
export const EDIT_CLIENT_DETAILS_REQUESTED = "EDIT_CLIENT_DETAILS_REQUESTED";
export const EDIT_CLIENT_DETAILS_SUCCESS = "EDIT_CLIENT_DETAILS_SUCCESS";
export const EDIT_CLIENT_DETAILS_FAIL = "EDIT_CLIENT_DETAILS_FAIL";
export const EDIT_CLIENT_DETAILS_CLEAR = "EDIT_CLIENT_DETAILS_CLEAR";
export const ADD_MODAL_CLEAR = "ADD_MODAL_CLEAR";
export const ASSIGN_AGENT_START = "ASSIGN_AGENT_START_CLIENT";
export const ASSIGN_AGENT_SUCCESS = "ASSIGN_AGENT_SUCCESS";
export const UPDATE_FINANCIAL_INFO_START = "UPDATE_FINANCIAL_INFO_START";
export const UPDATE_FINANCIAL_INFO_SUCCESS = "UPDATE_FINANCIAL_INFO_SUCCESS";
export const UPDATE_FINANCIAL_INFO_FAIL = "UPDATE_FINANCIAL_NFO_FAIL";
export const UPDATE_EMPLOYMENT_INFO_START = "UPDATE_EMPLOYMENT_INFO_START";
export const UPDATE_EMPLOYMENT_INFO_SUCCESS = "UPDATE_EMPLOYMENT_INFO_SUCCESS";
export const UPDATE_EMPLOYMENT_INFO_FAIL = "UPDATE_EMPLOYMENT_INFO_FAIL";
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const RESET_PASSWORD_CLEAR = "RESET_PASSWORD_CLEAR";

export const CLIENT_FORGOT_PASSWORD_START = "CLIENT_FORGOT_PASSWORD_START";
export const CLIENT_FORGOT_PASSWORD_CLEAR = "CLIENT_FORGOT_PASSWORD_CLEAR";

export const SEND_EMAIL_TO_RESET_PASSWORD_START = "SEND_EMAIL_TO_RESET_PASSWORD_START";
export const SEND_EMAIL_MODAL_CLEAR = "SEND_EMAIL_MODAL_CLEAR";

export const CLIENT_DISABLE_2FA_START = "CLIENT_DISABLE_2FA_START";
export const CLIENT_DISABLE_2FA_SUCCESS = "CLIENT_DISABLE_2FA_SUCCESS";
export const CLIENT_DISABLE_2FA_FAIL = "CLIENT_DISABLE_2FA_FAIL";

export const CONVERT_TO_IB_START = "CONVERT_TO_IB_START";
export const CONVERT_TO_IB_SUCCESS = "CONVERT_TO_IB_SUCCESS";
export const CONVERT_TO_IB_FAIL = "CONVERT_TO_IB_FAIL";
export const CONVERT_TO_IB_CLEAR = "CONVERT_TO_IB_CLEAR";

export const CONVERT_TO_CLIENT_START = "CONVERT_TO_CLIENT_START";
export const CONVERT_TO_CLIENT_SUCCESS = "CONVERT_TO_CLIENT_SUCCESS";
export const CONVERT_TO_CLIENT_FAIL = "CONVERT_TO_CLIENT_FAIL";
export const CONVERT_TO_CLIENT_CLEAR = "CONVERT_TO_CLIENT_CLEAR";

export const GET_MT5_MARKUP_START = "GET_MT5_MARKUP_START";
export const GET_MT5_MARKUP_SUCCESS = "GET_MT5_MARKUP_SUCCESS";
export const GET_MT5_MARKUP_FAIL = "GET_MT5_MARKUP_FAIL";
export const GET_MT5_MARKUP_CLEAR = "GET_MT5_MARKUP_CLEAR";

export const UPDATE_CLIENT_CALL_STATUS = "UPDATE_CLIENT_CALL_STATUS";
export const UPDATE_CLIENT_CALL_STATUS_SUCCESS = "UPDATE_CLIENT_CALL_STATUS_SUCCESS";
export const UPDATE_CLIENT_CALL_STATUS_FAILED = "UPDATE_CLIENT_CALL_STATUS_FAILED";

export const ADD_INTERESTED_PROJECT_START = "ADD_INTERESTED_PROJECT_START";
export const ADD_INTERESTED_PROJECT_SUCCESS = "ADD_INTERESTED_PROJECT_SUCCESS";
export const ADD_INTERESTED_PROJECT_FAIL = "ADD_INTERESTED_PROJECT_FAIL";

export const REMOVE_INTERESTED_PROJECT_START = "REMOVE_INTERESTED_PROJECT_START";
export const REMOVE_INTERESTED_PROJECT_SUCCESS = "REMOVE_INTERESTED_PROJECT_SUCCESS";
export const REMOVE_INTERESTED_PROJECT_FAIL = "REMOVE_INTERESTED_PROJECT_FAIL";