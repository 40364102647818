import classNames from "classnames";
import React, { useState } from  "react";
import { MetaTags } from "react-meta-tags";
import {
  Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import ReportPage from "./ReportPage";
import { transFormFields } from "./constants/transactions";
import { rentsFormFields } from "./constants/rents";
import { projectFormFields } from "./constants/project";
import { valuationsFormFields } from "./constants/valuations";
import { landFormFields } from "./constants/land";
import { buildingFormFields } from "./constants/building";
import { unitsFormFields } from "./constants/units";
import { brokersFormFields } from "./constants/brokers";
import { developersFormFields } from "./constants/developers";

export default function OpenSourceReports() {
  const tabs = [
    transFormFields,
    rentsFormFields,
    projectFormFields,
    valuationsFormFields,
    landFormFields,
    buildingFormFields,
    unitsFormFields,
    brokersFormFields,
    developersFormFields,
  ];

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => activeTab !== tab && setActiveTab(tab);

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Reports
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Open Source Report</h2>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody >
                  <Nav tabs>
                    {tabs.map((tabItem) => (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classNames({
                              active: activeTab === tabItem.tabId,
                            })}
                            onClick={() => toggle(tabItem.tabId)}
                          >
                            {tabItem.navLinkName}
                          </NavLink>
                        </NavItem>
                      </>
                    ))}
                  </Nav>

                  <TabContent activeTab={activeTab} className="p-3 text-muted" style={{
                    minHeight: "calc(100vh - 200px)"
                  }}>
                    {tabs.map((tabItem) => (
                      <>
                        <TabPane tabId={tabItem.tabId}>
                          <Row>
                            <Col sm="12">
                              <ReportPage
                                fields={tabItem.fields}
                                endpoint={tabItem.endpoint}
                                sort={tabItem.sort}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </>
                    ))}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
