import {
  ADD_DEVELOPERS_ERROR,
  ADD_DEVELOPERS_START,
  ADD_DEVELOPERS_SUCCESS,
  EDIT_DEVELOPERS_ERROR,
  EDIT_DEVELOPERS_START,
  EDIT_DEVELOPERS_SUCCESS,
  FETCH_DEVELOPERS_ERROR,
  FETCH_DEVELOPERS_START,
  FETCH_DEVELOPERS_SUCCESS,
} from "./actionTypes";


export const fetchDevelopers = (params = {}) => {
  return {
    type: FETCH_DEVELOPERS_START,
    payload: params
  };
};

export const fetchDevelopersSuccess = (data) => {
  return {
    type: FETCH_DEVELOPERS_SUCCESS,
    payload: data
  };
};

export const fetchDevelopersError = (error) => {
  return {
    type: FETCH_DEVELOPERS_ERROR,
    payload: { error }
  };
};

export const addDevelopers = (params = {}) => {
  return {
    type: ADD_DEVELOPERS_START,
    payload: params
  };
};

export const addDevelopersSuccess = (data) => {
  return {
    type: ADD_DEVELOPERS_SUCCESS,
    payload: data
  };
};

export const addDevelopersError = (error) => {
  return {
    type: ADD_DEVELOPERS_ERROR,
    payload: { error }
  };
};

export const editDevelopers = (params = {}) => {
  return {
    type: EDIT_DEVELOPERS_START,
    payload: params
  };
};

export const editDevelopersSuccess = (data) => {
  return {
    type: EDIT_DEVELOPERS_SUCCESS,
    payload: data
  };
};

export const editDevelopersError = (error) => {
  return {
    type: EDIT_DEVELOPERS_ERROR,
    payload: { error }
  };
};