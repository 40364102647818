import moment from "moment";

export const buildingColumns = [
  {
    dataField: "LAND_NUMBER",
    text: "Building Number",
  },
  {
    dataField: "PROP_SUB_TYPE_EN",
    text: "Property Sub Type",
  },
  {
    dataField: "ACTUAL_AREA",
    text: "Property Size (sq.m)",
  },
  {
    dataField: "COMMON_AREA",
    text: "Common Area",
  },
  {
    dataField: "ACTUAL_COMMON_AREA",
    text: "Actual Common Area",
  },
  {
    dataField: "BUILT_UP_AREA",
    text: "Built Up Area",
  },
  {
    dataField: "BLD_LEVELS",
    text: "Building Levels",
  },
  {
    dataField: "SHOPS",
    text: "Shops",
  },
  {
    dataField: "FLATS",
    text: "Flats",
  },
  {
    dataField: "OFFICES",
    text: "Offices",
  },
  {
    dataField: "SWIMMING_POOLS",
    text: "Swimming Pools",
  },
  {
    dataField: "ELEVATORS",
    text: "Elevators",
  },
  {
    dataField: "CREATION_DATE",
    text: "Creation Date",
    formatter: (row) => row?.CREATION_DATE ? moment(row.CREATION_DATE).format("DD/MM/YYYY") : ""
  },
  {
    dataField: "IS_OFFPLAN_EN",
    text: "Registration Type",
  },
  {
    dataField: "PRE_REGISTRATION_NUMBER",
    text: "Pre Registration Type",
  },
  {
    dataField: "IS_FREE_HOLD_EN",
    text: "Is Free Hold?",
  },
  {
    dataField: "IS_LEASE_HOLD_EN",
    text: "Is Lease Hold",
  },
  {
    dataField: "FLOORS",
    text: "Floors",
  },
  {
    dataField: "ROOMS",
    text: "Room(s)",
  },
  {
    dataField: "CAR_PARKS",
    text: "Parking",
  },
  {
    dataField: "LAND_NUMBER",
    text: "Land Number",
  },
  {
    dataField: "LAND_SUB_NUMBER",
    text: "Land Sub Number",
  },
  {
    dataField: "LAND_TYPE_EN",
    text: "Land Type",
  },
  {
    dataField: "MASTER_PROJECT_EN",
    text: "Master Project",
  },
  {
    dataField: "PROJECT_NUMBER",
    text: "Project Number",
  },
  {
    dataField: "PROJECT_EN",
    text: "Project Name",
  },
  {
    dataField: "AREA_EN",
    text: "Area",
  },
  {
    dataField: "ZONE_EN",
    text: "Zone",
  },
];

export const buildingFormFields = {
  tabId: "6",
  navLinkName: "Building",
  endpoint: "buildings",
  sort: "BUILDING_NUMBER_ASC",
  fields: [
    {
      id: 3,
      name: "Area",
      fieldName: "P_AREA_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
      ]
    },
    {
      id: 5,
      name: "Zone",
      fieldName: "P_ZONE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Deira",
          value: "1",
        },
        {
          label: "Dubai",
          value: "2",
        },
      ]
    },
    {
      id: 1,
      name: "From Date",
      fieldName: "P_FROM_DATE",
      required: false,
      type: "date",
    },
    {
      id: 2,
      name: "To Date",
      fieldName: "P_TO_DATE",
      required: false,
      type: "date",
    },
    {
      id: 3,
      name: "Is Free Hold?",
      fieldName: "P_IS_FREE_HOLD",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "2",
        },
      ]
    },
    {
      id: 3,
      name: "Is Lease Hold",
      fieldName: "P_IS_LEASE_HOLD",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "2",
        },
      ]
    },
    {
      id: 4,
      name: "Registration Type",
      fieldName: "P_IS_OFFPLAN",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Ready",
          value: "0",
        },
        {
          label: "Off Plan",
          value: "1",
        },
      ]
    },
  ],
};