import { transColumns } from "./transactions";
import { rentsColumns } from "./rents";
import { projectColumns } from "./project";
import { valuationsColumns } from "./valuations";
import { landColumns } from "./land";
import { buildingColumns } from "./building";
import { unitsColumns } from "./units";
import { brokersColumns } from "./brokers";
import { developersColumns } from "./developers";

export default {
  transColumns,
  rentsColumns,
  projectColumns,
  valuationsColumns,
  landColumns,
  buildingColumns,
  unitsColumns,
  developersColumns,
  brokersColumns,
};