import React, { useEffect } from "react";
import {
  Card, CardBody, CardTitle, Row, Col
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import TableLoader from "components/Common/Loader";
import { fetchLeadStagesStatsStart } from "store/actions";
import { startCase } from "lodash";

const ClientsStats = (props) => {
  const dispatch = useDispatch();
  const {
    callStatus, loading
  } = useSelector(state => state.dictionaryReducer);

  const {
    leadStatsTotal, leadStatsLoading
  } = useSelector(state => state.dashboardReducer);

  useEffect(() => {
    if (callStatus) {
      const list = Object.keys(callStatus || {});
      dispatch(fetchLeadStagesStatsStart({
        callStatus: list.join(",")
      }));
    }
  }, [callStatus]);

  
  if (loading || leadStatsLoading) {
    return <TableLoader />;
  }

  return (
    <React.Fragment>
      <Card className="card-animate">
        <CardBody>
          <CardTitle>Lead Stages</CardTitle>
          <Row className="col-card-same-height mt-5">
            {callStatus && Object.keys(leadStatsTotal || {})?.filter(
              item => leadStatsTotal[item]
            )?.map((item, index) => (
              <Col key={index} sm={6} md={6} lg={6}  className="mb-4">
                <Link to={`/leads?callStatus=${item}`}>
                  <Col sm={12} className="d-flex align-items-center">
                    <div className="circle-stat">
                      {leadStatsTotal[item] || 0}
                    </div>
                    {startCase(item.split("_").join(" ")?.toLowerCase())}
                  </Col>
                </Link>
              </Col>
            ))}
          </Row>

        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default (withTranslation()(ClientsStats));