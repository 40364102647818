import classNames from "classnames";

export default {
  loadMenus: (
    translate,
    {
      profileMetaInfo: { menu, settings, riskManagement },
      userPermissions: { get: getUser } = {},
      clientPermissions: { get: getClient, getAssigned: getAssignedClients } = {},
      leadsPermissions: { get: getLeads, getAssigned: getAssignedLeads, getSubordinates } = {},
      markupsPermissions: { get: getMarkups } = {},
      rolesPermissions: { get: getRoles } = {},
      dictionariesPermissions: { get: getDictionaries } = {},
      feeGroupsPermissions: { get: getFeeGroup } = {},
      systemEmailsPermissions: { get: getSystemEmail } = {},
      emailConfigPermissions: { get: getEmailConfig } = {},
      teamsPermissions: { get: getTeams } = {},
      leadAssignPermissions: { get: getLeadsAssign } = {},
      orderProfitPermissions: { get: getOrderProfit } = {},
      transactionProfitPermissions: { get: getTransactionProfit } = {},
      todosPermissions: { get: getTodos } = {},
      userLogsPermissions: { get: getUserLogs } = {},
      emailCampaignPermissions: { get: getEmailCampaign } = {},
      projectPermissions: { get: getProject } = {},
      facebookCampaignsPermissions: { get: getFacebookCampaigns } = {},
      facebookFormsFieldsPermissions: { get: getFacebookFormsFields } = {},
    }
  ) => [
    {
      menuItemLink: "/dashboard",
      menuItemName: translate("Dashboard"),
      menuItemIcon: "home",
      className: "",
      visibility: (menu || {}).dashboard,
    },
    {
      menuItemLink: "/clients",
      menuItemName: translate("Clients"),
      menuItemIcon: "users",
      className: `${!getClient ? "d-none" : ""}`,
      visibility: (menu || {}).clients,
    },
    {
      menuItemLink: "/leads",
      menuItemName: translate("Leads"),
      menuItemIcon: "monitor",
      className: `${!getLeads && !getAssignedLeads ? "d-none" : ""}`,
      visibility: (menu || {}).leads,
    },
    // {
    //   menuItemLink: "#",
    //   menuItemName: translate("Requests"),
    //   menuItemIcon: "trending-up",
    //   className: "has-arrow",
    //   visibility: (menu || {}).requests,
    //   hasSubMenus: true,
    //   subMenus: [
    //   ],
    // },
    // {
    //   menuItemLink: "/transactions",
    //   menuItemName: translate("Transactions"),
    //   menuItemIcon: "trending-up",
    //   className: "has-arrow",
    //   visibility: (menu || {}).transactions,
    //   hasSubMenus: true,
    //   subMenus: [
    //     {
    //       menuItemLink: "/transactions/deposit",
    //       menuItemName: translate("Deposit"),
    //       className: `${!getDeposits ? "d-none" : ""}`,
    //       visibility: (transactions || {}).deposit,
    //     },
    //     {
    //       menuItemLink: "/transactions/withdrawals",
    //       menuItemName: translate("Withdrawal"),
    //       className: `${!getWithdrawals ? "d-none" : ""}`,
    //       visibility: (transactions || {}).withdrawal,
    //     },
    //     {
    //       menuItemLink: "/transactions/internal-transfer",
    //       menuItemName: translate("Internal Transfer"),
    //       className: "",
    //       visibility: (transactions || {}).internalTransfer,
    //     },
    //     {
    //       menuItemLink: "/transactions/credit",
    //       menuItemName: translate("Credit"),
    //       className: "",
    //       visibility: (transactions || {}).credit,
    //     },
    //     {
    //       menuItemLink: "/transactions/convert",
    //       menuItemName: translate("Convert"),
    //       className: "",
    //       visibility: (transactions || {}).convert,
    //     },
    //   ],
    // },
    {
      menuItemLink: "/positions",
      menuItemName: translate("Positions"),
      menuItemIcon: "cast",
      className: "",
      visibility: false,
    },
    {
      menuItemLink: "/calendar",
      menuItemName: translate("Calendar"),
      menuItemIcon: "calendar",
      className: `${!getTodos ? "d-none" : ""}`,
      visibility: true,
    },
    {
      menuItemLink: "/d",
      menuItemName: translate("Marketing"),
      menuItemIcon: "tool",
      className: classNames("has-arrow", {
        "d-none": !getSystemEmail && !getEmailConfig && !getEmailCampaign,
      }),
      visibility: (menu || {}).marketing,
      hasSubMenus: true,
      subMenus: [
        {
          menuItemLink: "/email-campaigns/",
          menuItemName: translate("Campaigns"),
          className: `${!getEmailCampaign ? "d-none" : ""}`,
          visibility: (settings || {}).emailCampaign,
        },
        {
          menuItemLink: "/email-campaigns/templates",
          menuItemName: translate("Campaign Templates"),
          className: `${!getEmailCampaign ? "d-none" : ""}`,
          visibility: (settings || {}).emailCampaign,
        },
        {
          menuItemLink: "/email-campaigns/unsubscribers",
          menuItemName: translate("Campaign Unsubscribers"),
          className: `${!getEmailCampaign ? "d-none" : ""}`,
          visibility: (settings || {}).emailCampaign,
        },
      ],
    },
    {
      menuItemLink: "/open-data",
      menuItemName: translate("Open Source Report"),
      menuItemIcon: "book",
      className: "",
      visibility: true,
    },
    {
      menuItemLink: "/reports",
      menuItemName: translate("Reports"),
      menuItemIcon: "users",
      className: `${!getClient ? "d-none" : ""}`,
      visibility: (menu || {}).clients,
    },
    {
      menuItemLink: "/settings",
      menuItemName: translate("Settings"),
      menuItemIcon: "tool",
      className: "has-arrow",
      visibility: (menu || {}).settings,
      hasSubMenus: true,
      subMenus: [
        {
          menuItemLink: "/project",
          menuItemName: translate("Project"),
          className: `${!getProject ? "d-none" : ""}`,
          visibility: (settings || {}).projects,
        },
        {
          menuItemLink: "/developer",
          menuItemName: translate("Developer"),
          className: `${!getProject ? "d-none" : ""}`,
          visibility: (settings || {}).projects,
        },
        {
          menuItemLink: "/dictionaries",
          menuItemName: translate("Dictionaries"),
          className: `${!getDictionaries ? "d-none" : ""}`,
          visibility: (settings || {}).dictionaries,
        },
        {
          menuItemLink: "/users",
          menuItemName: translate("Users"),
          className: `${!getUser ? "d-none" : ""}`,
          visibility: (settings || {}).users,
        },
        {
          menuItemLink: "/roles",
          menuItemName: translate("Roles"),
          className: `${!getRoles ? "d-none" : ""}`,
          visibility: (settings || {}).roles,
        },
        {
          menuItemLink: "/system-emails",
          menuItemName: translate("System Emails"),
          className: `${!getSystemEmail ? "d-none" : ""}`,
          visibility: (settings || {}).systemEmails,
        },
        
        {
          menuItemLink: "/email-config",
          menuItemName: translate("Email Configurations"),
          className: `${!getEmailConfig ? "d-none" : ""}`,
          visibility: (settings || {}).emailConfig,
        },
        // {
        //   menuItemLink: "/banks",
        //   menuItemName: translate("Bank Accounts"),
        //   className: `${!getCompanyBanks ? "d-none" : ""}`,
        //   visibility: (settings || {}).companyBanks,
        // },
        {
          menuItemLink: "/user-logs",
          menuItemName: translate("User Logs"),
          className: `${!getUserLogs ? "d-none" : ""}`,
          visibility: (settings || {}).userLogs,
        },
        {
          menuItemLink: "/teams",
          menuItemName: translate("Teams"),
          className: `${!getTeams ? "d-none" : ""}`,
          visibility: (settings || {}).teams,
        },
        // {
        //   menuItemLink: "/targets",
        //   menuItemName: translate("Targets"),
        //   className: `${!getTargets ? "d-none" : ""}`,
        //   visibility: (settings || {}).targets,
        // },
        {
          menuItemLink: "/lead-assign",
          menuItemName: translate("Lead Assign"),
          className: `${!getLeadsAssign ? "d-none" : ""}`,
          visibility: (settings || {}).leadAssign,
        },
        {
          menuItemLink: "/facebook-campaigns",
          menuItemName: translate("Facebook Campaigns"),
          className: `${!getFacebookCampaigns ? "d-none" : ""}`,
          visibility: (settings || {}).facebookCampaigns,
        },
        {
          menuItemLink: "/facebook-fields",
          menuItemName: translate("Facebook Form Fields"),
          className: `${!getFacebookFormsFields ? "d-none" : ""}`,
          visibility: (settings || {}).facebookFormsFields,
        },
      ],
    },
  ],
};
