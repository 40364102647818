import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { editProject } from "store/actions";
import * as API from "apis/real_estate";

const loadClientsOptions = async (search, page) => {
  const payload = {
    page: page,
    limit: 30,
  };
  if (search) {
    payload.searchText = search;
  }
  const output = [];
  const data = await API
    .getDevelopers({
      payload: payload,
    });
  data?.docs?.map(function (item) {
    output.push({
      value: item,
      label: item.name,
    });
  });
  return {
    options: output,
    hasMore: data.hasNextPage,
  };
};
export default function EditProject(props) {
  const {
    toggle,
    project,
  } = props;

  const dispatch = useDispatch();
  const [developer, setDeveloper] = useState(null);

  const handleEdit = (e, v) => {
    dispatch(editProject({
      id: project._id,
      developer: developer.value._id,
      ...v,
    }));
  };

  const loadPageOptions = async (q, prevOptions, { page }) => {
    const { options, hasMore } = await loadClientsOptions(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    if (project) {
      setDeveloper({
        value: project.developer,
        label: project.developer?.name,
      });
    }
  }, [project]);

  return (
    <Modal isOpen={project} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        Edit Project - {project.name}
      </ModalHeader>
      <ModalBody>
        <AvForm
          className='p-4'
          onValidSubmit={handleEdit}
        >
          <Row className="mb-3">
            <Col md="12">
              <Label>{props.t("Project Name")}</Label>
              <div>
                <AvField
                  type="text"
                  name="name"
                  value={project?.name || ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a name",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md="12">
              <Label>{props.t("Project Size")}</Label>
              <div>
                <AvField
                  type="text"
                  name="size"
                  value={project?.size || ""}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a size",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md="12">
              <Label>{props.t("Developer Name")}</Label>
              <div>
                <AsyncPaginate
                  additional={{ page: 1 }}
                  value={developer}
                  loadOptions={loadPageOptions}
                  placeholder="Select Developer Name ..."
                  onChange={(obj) => { setDeveloper(obj) }}
                  errorMessage="Please select Developer"
                  validate={{ required: { value: true } }}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Button color="primary" className="btn btn-primary" type="submit" disabled={!project}>
              {props.t("Update")}
            </Button>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}
