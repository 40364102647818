import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Row
} from "reactstrap";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import TableLoader from "components/Common/Loader";
import moment from "moment";
import CustomPagination from "components/Common/CustomPagination";
import columns from "./constants/columns";

const generateField = (field, onChange) => {
  switch (field.type) {
    case "date":
      return <AvField
        type="date"
        name={field.fieldName}
        label={field.name}
        placeholder={`Enter ${field.name}`}
        onChange={onChange}
        validate={{
          required: { value: field.required },
          date: { format: "DD/MM/YYYY" }
        }}
      >
      </AvField>;
    case "select":
      return <AvFieldSelect
        name={field.fieldName}
        label={field.name}
        placeholder={`Enter ${field.name}`}
        options={field.options}
      />;
    case "text":
      return <AvField
        type="text"
        name={field.fieldName}
        label={field.name}
        placeholder={`Enter ${field.name}`}
        onChange={onChange}
        validate={{
          required: { value: field.required },
        }}
      >
      </AvField>;
  }
};

const getColumns = (endpoint) => {
  switch (endpoint) {
    case "transactions":
      return columns.transColumns;
    case "rents":
      return columns.rentsColumns;
    case "projects":
      return columns.projectColumns;
    case "valuations":
      return columns.valuationsColumns;
    case "lands":
      return columns.landColumns;
    case "buildings":
      return columns.buildingColumns;
    case "brokers":
      return columns.brokersColumns;
    case "developers":
      return columns.developersColumns;
    default: return [];
  }
};

export default function ReportPage(props) {
  const {
    fields = [],
    endpoint,
  } = props;
  const {
    t
  } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [payload, setPayload] = useState({});

  const convertDate = (date) => {
    if (!date) return;
    // Incoming format : YYYY-MM-DD
    // Outgoing format : MM/DD/YYYY
    const [
      year,
      month,
      day
    ] = date.split("-");
    return `${month}/${day}/${year}`;
  };
  const handleSubmit = (e, v) => {
    setPayload(v);
    setPage(1);
  };

  useEffect(() => {
    if (endpoint && payload) getData();
  }, [page, sizePerPage, payload]);

  const getExtraParams = (endpoint) => {
    switch (endpoint) {
      case "transactions":
        return {
          P_SORT: "",
        };
      case "rents":
        return {
          P_SORT: "",
        };
      case "projects":
        return {
          P_PRJ_STATUS: "",
          P_SORT: "PROJECT_NUMBER_ASC",
          P_ZONE_ID: "",
          P_PRJ_TYPE_ID: "",
        };
      case "valuations": 
        return {
          P_SORT: "PROPERTY_TOTAL_VALUE_ASC",
        };
      case "lands": 
        return {
          P_SORT: "LAND_NUMBER_ASC",
          P_MASTER_PROJECT: "",
          P_PROP_SB_TYPE_ID: "",
        };
      case "buildings": 
        return {
          P_SORT: "BUILDING_NUMBER_ASC",
        };
      case "units": 
        return {
          P_SORT: "UNIT_NUMBER_ASC",
        };
      case "brokers":
        return {
          P_SORT: "BROKER_NUMBER_ASC",
        };
      case "developers":
        return {
          P_SORT: "DEVELOPER_NUMBER_ASC",
        };
      default: return {};
    }
  };

  const getDates = () => {
    if (endpoint === "buildings" || endpoint === "developers") {
      let d = {};
      if (from) {
        d = {
          ...d,
          P_FROM_DATE: convertDate(from),
        };
      }
      if (to) {
        d = {
          ...d,
          P_TO_DATE: convertDate(to),
        };
      }
      return d;
    }
    if (endpoint !== "lands" && endpoint !== "units") {
      return {
        P_FROM_DATE: convertDate(from),
        P_TO_DATE: convertDate(to),
      };
    }
  };

  const getData = () => {
    const d = {
      ...payload,
      ...getDates(),
      P_TAKE: `${sizePerPage}`,
      P_SKIP: `${(page - 1) * sizePerPage}`,
      ...getExtraParams(endpoint),
    };
    setLoading(true);
    fetch(`https://gateway.dubailand.gov.ae/open-data/${endpoint}`, {
      method: "POST",
      body: JSON.stringify(d),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    }).then(res => res.json()).then(res => {
      if (res.response && res.responseCode === 200) {
        setData(res.response?.result);
        if (res.response?.result?.length > 0) {
          setTotal(res.response?.result[0]?.TOTAL);
        } else {
          setTotal(0);
        }
      } else {
        console.log(res);
        throw new Error("Can't find data");
      }
    }).catch(err => {
      setTotal(0);
      console.log(err);
      setData([]);
    }).finally(() => {
      setLoading(false);
    });
  };

  if (!fields) return;

  const columns = getColumns(endpoint);
  
  return (
    <div className="me-2 mb-2">
      <div className="position-relative">
        <AvForm
          className='p-1'
          onValidSubmit={handleSubmit}
        >
          <Row>
            {fields?.map((field, index) => <Col
              key={index}
              md={3}
            >
              {generateField(field, (e) => {
                if (field.fieldName === "P_FROM_DATE") {
                  setFrom(e.target.value);
                } else if (field.fieldName === "P_TO_DATE") {
                  setTo(e.target.value);
                }
              })}
            </Col>)}
          </Row>
          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              className={"btn btn-primary"}
              type="submit"
            >{t("Submit")}</Button>
          </div>
        </AvForm>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            className={"btn btn-primary"}
            onClick={
              () => {
                // download data as csv
                setLoading(true);
                const d = {
                  ...payload,
                  ...getDates(),
                  P_TAKE: `${total}`,
                  P_SKIP: `${0}`,
                  ...getExtraParams(endpoint),
                };
                fetch(`https://gateway.dubailand.gov.ae/open-data/${endpoint}`, {
                  method: "POST",
                  body: JSON.stringify(d),
                  headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                  },
                }).then(res => res.json()).then(res => {
                  if (res.response && res.responseCode === 200) {
                    const csv = [
                      columns.map(c => c.text).join(","),
                      ...res.response?.result?.map(row => columns.map(c => {
                        if (c.formatter) {
                          return c.formatter(row);
                        }
                        // need to ignore line breaks
                        return `${row[c.dataField]}`.replace(/(\r\n|\n|\r)/gm, " ");
                      }).join(","))
                    ].join("\n");
                    const blob = new Blob([csv], { type: "text/csv" });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.setAttribute("hidden", "");
                    a.setAttribute("href", url);
                    a.setAttribute("download", `${endpoint}-${page}-${moment().format("DD-MM-YYYY")}.csv`);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  } else {
                    throw new Error("Can't find data");
                  }
                }).catch(err => {
                }).finally(() => {
                  setLoading(false);
                });
                
              }
            }
          >{t("Download as CSV")}</Button>
        </div>
        <div style={{
          overflowX: "auto",

        }}>
          <Table
            id="tech-companies-1"
            className="table  table-hover my-5"
          >
            <Thead className="text-center table-light" >
              <Tr>
                {columns.map((column, index) =>
                  <Th data-priority={index} key={index}>{column.text}</Th>
                )}
              </Tr>
            </Thead>
            <Tbody className="text-center" style={{ fontSize: "13px" }}>
              {loading && <TableLoader colSpan={4} />}
              {!loading && data.length === 0 && 
                <>
                  <Tr style={{
                    height: "50vh"
                  }}>
                    <Td colSpan={"100%"} className="fw-bolder text-center" >
                      <h3 className="fw-bolder text-center">No records</h3>
                    </Td>
                  </Tr>
                </>
              }
              {!loading && data.map((row, rowIndex) =>
                <Tr key={rowIndex}>
                  {columns.map((column, index) =>
                    <Td key={`${rowIndex}-${index}`}>
                      { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                    </Td>
                  )}
                </Tr>
              )}
            </Tbody>
          </Table>
          {total !== 0 && <CustomPagination
            totalPages={Math.ceil(total / sizePerPage)}
            docs={data}
            page={page}
            currentPage={page}
            totalDocs={total}
            setSizePerPage={setSizePerPage}
            sizePerPage={sizePerPage}
            hasNextPage={page < Math.ceil(total / sizePerPage)}
            hasPrevPage={page > 1}
            onChange={(page, limit) => {
              setPage(page);
              setSizePerPage(limit);
              getData();
            }}
          />}
        </div>
      </div>
    </div>
  );
}
