import moment from "moment";

export const developersColumns = [
  {
    dataField: "DEVELOPER_NUMBER",
    text: "Developer Number",
  },
  {
    dataField: "DEVELOPER_EN",
    text: "Developer Name",
  },
  {
    dataField: "GENDER_EN",
    text: "Gender",
  },
  {
    dataField: "REGISTRATION_DATE",
    text: "Registration Date",
    formatter: (row) => moment(row.REGISTRATION_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "LICENSE_SOURCE_EN",
    text: "License Source",
  },
  {
    dataField: "LICENSE_TYPE_EN",
    text: "License Type",
  },
  {
    dataField: "WEBPAGE",
    text: "Website",
  },
  {
    dataField: "PHONE",
    text: "Phone",
  },
  {
    dataField: "FAX",
    text: "Fax",
  },
  {
    dataField: "LICENSE_NUMBER",
    text: "License Number",
  },
  {
    dataField: "LICENSE_ISSUE_DATE",
    text: "License Issue Date",
    formatter: (row) => moment(row.LICENSE_ISSUE_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "LICENSE_EXPIRY_DATE",
    text: "License End Date",
    formatter: (row) => moment(row.LICENSE_EXPIRY_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "CHAMBER_OF_COMMERCE_NO",
    text: "Chamber of Commerce No",
  },
];

export const developersFormFields = {
  tabId: "9",
  navLinkName: "Developer",
  endpoint: "developers",
  sort: "DEVELOPER_NUMBER_ASC",
  fields: [
    {
      id: 333,
      name: "Name",
      fieldName: "P_NAME",
      required: false,
      type: "text",
    },
    {
      id: 1,
      name: "From Date",
      fieldName: "P_FROM_DATE",
      required: false,
      type: "date",
    },
    {
      id: 2,
      name: "To Date",
      fieldName: "P_TO_DATE",
      required: false,
      type: "date",
    },
  ],
};