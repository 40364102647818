import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { editDevelopers } from "store/actions";

export default function EditDeveloper(props) {
  const {
    toggle,
    developer,
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = (e, v) => {
    dispatch(editDevelopers({
      id: developer._id,
      ...v,
    }));
  };

  return (
    <Modal isOpen={developer} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        Edit Developer
      </ModalHeader>
      <ModalBody>
        <AvForm
          className='p-4'
          onValidSubmit={handleSubmit}
        >
          <Row className="mb-3">
            <Col md="12">
              <Label>{props.t("Developer Name")}</Label>
              <div>
                <AvField
                  type="text"
                  name="name"
                  value={developer?.name || ""}
                  placeholder="Enter Developer Name"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter a name",
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Button color="primary" className="btn btn-primary" type="submit" >
              {props.t("Update")}
            </Button>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}
