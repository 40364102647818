import React, {
  useState,
} from "react";
import { withTranslation } from "react-i18next";
import {
  useDispatch, connect, useSelector
} from "react-redux";
import moment from "moment";
import {
  Button,
  Row, Col,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { fetchReportStart } from "store/reports/actions";

import Loader from "components/Common/Loader";
import { downloadReport } from "apis/reports";
import SendReportEmail from "./SendReportEmail";
import AvFieldSelect from "components/Common/AvFieldSelect";

function UsersAddModal(props) {
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState({});
  const [type, setType] = useState("countries");
  const { callStatus } = useSelector((state) => state.dictionaryReducer);

  const submitReportSearch = (e, values) => {
    props.setReportColumns(values.reportType);
    setSelectedValues(values);
    dispatch(fetchReportStart({
      ...values,
    }));
  };

  const defaultValues = {
    reportType: "countries",
    dateFrom: moment().subtract(1, "months").format("YYYY-MM-DD"),
    dateTo: moment().add(1, "day").format("YYYY-MM-DD"),
  };


  return (
    <React.Fragment >
      <div className=" me-2 mb-2">
        <div className="position-relative">
          <AvForm
            className='p-1'
            onValidSubmit={(e, v) => {
              submitReportSearch(e, v);
            }}
            model={defaultValues}
          >
            <Row>
              <Col className="col-2">
                <label >Select Report Type</label>
                <AvField
                  type="select"
                  name="reportType"
                  className="form-select"
                  errorMessage={props.t("please select report Type")}
                  validate={{ required: { value: true } }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setType(e.target.value);
                  }}
                >
                  <option value="">{props.t("Select Report")}</option>
                  <option value="countries">{props.t("Country wise")}</option>
                  <option value="campaigns">{props.t("Campaign Report")}</option>
                  <option value="leads">{props.t("Leads")}</option>
                </AvField>
              </Col>
              <Col className="col-3">
                <AvField
                  name="dateFrom"
                  label={props.t("Date From")}
                  type="date"
                  errorMessage={props.t("Select date From")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col className="col-3">
                <AvField
                  name="dateTo"
                  label={props.t("Date To")}
                  type="date"
                  errorMessage={props.t("Select date To")}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col className="col-3">
                <label > Agent </label>
                <AvField
                  type="select"
                  name="agent"
                  errorMessage={props.t("Select Agent")}
                >
                  <option value="">{props.t("All")}</option>
                  {props.salesAgent.map((obj, index) => <option key={index} value={obj._id}>{`${obj.firstName} ${obj.lastName}`}</option>)}
                </AvField>
              </Col>
              <Col className='col-1 text-center d-flex justify-content-center align-items-center mt-3'>
                {
                  props.addLoading
                    ?
                    <Loader />
                    :
                    <Button type="submit" color="primary" className="" disabled={props.loading}>
                      {props.t("Search")}
                    </Button>
                }
              </Col>
            </Row>
            {
              type === "leads" && <Row>
                <Col className="col-12">
                  <AvFieldSelect
                    name="callStatus"
                    label={props.t("Call Status")}
                    options={Object.keys(callStatus).map((key) => ({
                      label: props.t(key),
                      value: key,
                    }))}
                    errorMessage={props.t("please select call status")}
                    validate={{ required: { value: true } }}
                    ismulti
                  />
                </Col>
              </Row>
            }
            <div className="d-flex justify-content-end gap-2">
              <Button
                color="primary"
                size="sm"
                disabled={!type || props.loading || props.docs.length === 0}
                onClick={() => {
                  const csv  = [
                    props?.columns.map((obj) => obj.text)?.join(","),
                    ...props?.docs.map(row => props?.columns.map(c => {
                      if (c.formatter) {
                        return c.formatter(row);
                      }
                      // need to ignore line breaks
                      return `${row[c.dataField]}`.replace(/(\r\n|\n|\r)/gm, " ");
                    }).join(","))
                  ].join("\n");
                  const blob = new Blob([csv], { type: "text/csv" });
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.setAttribute("hidden", "");
                  a.setAttribute("href", url);
                  a.setAttribute("download", `Report-${moment().format("DD-MM-YYYY")}.csv`);
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                }}>
                {props.t("Download Report")}
              </Button>
            </div>
          </AvForm>
        </div>
      </div>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0"
          data-pattern="priority-columns"
        >
          {props.children}
          {/* <CustomPagination
            {...props.reportsReducer}
            setSizePerPage={setSizePerPage}
            sizePerPage={sizePerPage}
            onChange={paginationLoad}
          /> */}
        </div>
      </div>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  loading: state.reportsReducer.loading || false,
  salesAgent: state.usersReducer.salesAgent || [],
  reportsReducer: state.reportsReducer || {},
  docs: state.reportsReducer.docs || [],
});
export default connect(mapStateToProps, null)(withTranslation()(UsersAddModal));
