import moment from "moment";

export const projectColumns = [
  {
    dataField: "PROJECT_NUMBER",
    text: "Project Number",
  },
  {
    dataField: "PROJECT_EN",
    text: "Project Name",
  },
  {
    dataField: "DEVELOPER_NUMBER",
    text: "Developer Number",
  },
  {
    dataField: "DEVELOPER_EN",
    text: "Developer Name",
  },
  {
    dataField: "START_DATE",
    text: "Start Date",
    formatter: (row) => row.START_DATE ? moment(row.START_DATE).format("DD/MM/YYYY") : ""
  },
  {
    dataField: "END_DATE",
    text: "End Date",
    formatter: (row) => row.END_DATE ? moment(row.END_DATE).format("DD/MM/YYYY")  : ""
  },
  {
    dataField: "ADOPTION_DATE",
    text: "Adoption Date",
    formatter: (row) => row.ADOPTION_DATE ? moment(row.ADOPTION_DATE).format("DD/MM/YYYY") : ""
  },
  {
    dataField: "PRJ_TYPE_EN",
    text: "Project Type",
  },
  {
    dataField: "PROJECT_VALUE",
    text: "Project Value",
  },
  {
    dataField: "ESCROW_ACCOUNT_NUMBER",
    text: "Escrow Account Number",
  },
  {
    dataField: "PROJECT_STATUS",
    text: "Project Status",
  },
  {
    dataField: "PERCENT_COMPLETED",
    text: "Completed (%)",
  },
  {
    dataField: "INSPECTION_DATE",
    text: "Inspection Date",
    formatter: (row) => row.INSPECTION_DATE ? moment(row.INSPECTION_DATE).format("DD/MM/YYYY") : ""
  },
  {
    dataField: "COMPLETION_DATE",
    text: "Completion Date",
    formatter: (row) => row.COMPLETION_DATE ? moment(row.COMPLETION_DATE).format("DD/MM/YYYY") : ""
  },
  {
    dataField: "DESCRIPTION_EN",
    text: "Description",
  },
  {
    dataField: "ZONE_EN",
    text: "Zone Authority",
  },
  {
    dataField: "CNT_LAND",
    text: "Total Lands",
  },
  {
    dataField: "CNT_BUILDING",
    text: "Total Buildings",
  },
  {
    dataField: "CNT_VILLA",
    text: "Total Villas",
  },
  {
    dataField: "MASTER_PROJECT_EN",
    text: "Master Project",
  },
];

export const projectFormFields = {
  tabId: "3",
  navLinkName: "Project",
  endpoint: "projects",
  sort: "PROJECT_NUMBER_ASC",
  fields: [
    {
      id: 333,
      name: "Date",
      fieldName: "P_DATE_TYPE",
      required: false,
      type: "select",
      options: [
        {
          label: "Start Date",
          value: "1",
        },
        {
          label: "End Date",
          value: "2",
        },
        {
          label: "Adoption Date",
          value: "3",
        },
        {
          label: "Completion Date",
          value: "4",
        },
      ]
    },
    {
      id: 1,
      name: "From Date",
      fieldName: "P_FROM_DATE",
      required: true,
      type: "date",
    },
    {
      id: 2,
      name: "To Date",
      fieldName: "P_TO_DATE",
      required: true,
      type: "date",
    },
    {
      id: 3,
      name: "Area",
      fieldName: "P_AREA_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
      ]
    },
    {
      id: 4,
      name: "Property Status",
      fieldName: "P_PRJ_STATUS",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Freezed",
          value: "FREIEZED",
        },
        {
          label: "Under Reviewing",
          value: "2",
        },
        {
          label: "Under Cancellation Decision",
          value: "3",
        },
        {
          label: "Cancelled",
          value: "5",
        },
        {
          label: "Finished",
          value: "6",
        },
        {
          label: "Active",
          value: "7",
        },
        {
          label: "Conditional Activating",
          value: "8",
        },
        {
          label: "Under Cancellation Notification",
          value: "8",
        },
      ]
    },
  ]
};