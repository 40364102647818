import React from "react";

// i18n
import { withTranslation } from "react-i18next";
import Loader from "components/Common/Loader";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ToolTipData from "components/Common/ToolTipData";

function ClientDetailsHeader() {
  const [kycStatus, setKycStatus] = React.useState("Pending");
  const {
    clientDetails,
    clientProfileloading,
  } = useSelector(state => state.clientReducer);
  const {
    leads
  } = useSelector(state => state.leadReducer);

  const history = useHistory();

  React.useEffect(() => {
    if (clientDetails && clientDetails.stages) {
      if (clientDetails.stages.kycRejected) setKycStatus("Rejected");
      else if (clientDetails.stages.kycApproved) setKycStatus("Approved");
      else setKycStatus("Pending");
    }
    
  }, [clientDetails]);
  /**
   * 
   * {
  "madeDeposit":false,
  "emailVerified":false,
  "phoneVerified":false,
  "individual":{
  "updateProfile":false,
  "startTrading":false
  }
  }
  * 
  */
  return (
    <React.Fragment>
      {
        clientDetails?.isLead && <>
          <div className="d-flex justify-content-between">
            <div>
              <Button
                color="primary"
                id="tooltip-previous"
                onClick={() => {
                  if (!leads || leads?.findIndex(lead => lead._id === clientDetails._id) === 0 || leads?.length === 0) {
                    return history.push("/leads");
                  }
                  const index = leads?.findIndex(lead => lead._id === clientDetails._id);
                  if (index !== -1) {
                    const prevLead = leads?.[index - 1];
                    history.push(`/clients/${prevLead?._id}/profile`);
                  }
                }}
              >
                {"<"}
                <ToolTipData
                  target={"tooltip-previous"}
                  placement="right"
                  data={"Previous"}
                />
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                toolTip="Next"
                id="tooltip-next"
                onClick={() => {
                  if (!leads || leads?.findIndex(lead => lead._id === clientDetails._id) === leads?.length - 1 || leads?.length === 0) {
                    return history.push("/leads");
                  }
                  const index = leads?.findIndex(lead => lead._id === clientDetails._id);
                  if (index !== -1) {
                    const nextLead = leads[index + 1];
                    history.push(`/clients/${nextLead._id}/profile`);
                  }
                }}
              > {">"}
                <ToolTipData
                  target={"tooltip-next"}
                  placement="left"
                  data={"Next"}
                /></Button>
            </div>
          </div>
        </>
      }
      <div className="row p-2 client-detail-header">
        <div className="name" >
          <div className="card-h-100 card card-animate">
            <div className="card-body">
              <div className="align-items-center">
                {clientProfileloading && <Loader />}
                {!clientProfileloading &&
                  <div >
                    <span className="text-muted mb-3 lh-1 d-block text-truncate">
                      {clientDetails.recordId}
                    </span>
                    <h6 className="mb-1">
                      <span className="counter-value">
                        {clientDetails.firstName + " " + clientDetails.lastName}
                      </span>
                    </h6>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="status">
          <div className="card-h-100 card card-animate">
            <div className="card-body">
              <div className="align-items-center row">
                {clientProfileloading && <Loader />}
                {!clientProfileloading &&
                  <div >
                    <span className="text-muted mb-3 lh-1 d-block text-truncate">
                      Created on
                    </span>
                    <h6 className="mb-1">
                      <span className="counter-value">
                        {clientDetails.createdAt ? (clientDetails.createdAt).split("T")[0] : " "}
                      </span>
                    </h6>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="hierarchy">
          <div className="card-h-100 card card-animate">
            <div className="card-body">
              <div className="align-items-center row">
                {clientProfileloading && <Loader />}
                {!clientProfileloading &&
                  <>
                    <div className="">
                      <span className="text-muted mb-3 lh-1 d-block text-truncate">
                        Status
                      </span>
                      <h6 className="mb-1">
                        <span className="counter-value">
                          {clientDetails.isActive ? "Active" : "Inactive"}
                        </span>
                      </h6>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="date">
          <div className="card-h-100 card card-animate">
            <div className="card-body">
              <div className="align-items-center row">
                {clientProfileloading && <Loader />}
                {!clientProfileloading &&
                  <>
                    <div className="">
                      <span className="text-muted mb-3 lh-1 d-block text-truncate">
                        Type
                      </span>
                      <h6 className="mb-1">
                        <span className="counter-value">
                          {clientDetails.category}
                        </span>
                      </h6>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        {/* <div className="type">
          <div className="card-h-100 card card-animate">
            <div className="card-body">
              <div className="align-items-center row">
                {clientProfileloading && <Loader />}
                {!clientProfileloading &&
                  <div >
                    <span className="text-muted mb-3 lh-1 d-block text-truncate">
                      
                    </span>
                    <h6 className="mb-1">
                      <span className="counter-value">
                        {kycStatus}
                      </span>
                    </h6>
                  </div>
                }
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment >
  );
}

export default (withTranslation()(ClientDetailsHeader));
