import React from "react";

import CreatableSelect from "react-select/creatable";

const defaultComponent = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const CustomCreatableSelect = (props) => {

  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState([]);

  React.useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);


  const {
    isClearable = true,
    isMulti = true,
    placeholder = "Type something and press enter...",
    components = defaultComponent,
    label = "Custom Creatable Select",
    dispatchState,
  } = props;


  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        if (dispatchState) {
          dispatchState([...value.map((item) => item.value), inputValue]);
        }
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable={isClearable}
      isMulti={isMulti}
      menuIsOpen={false}
      onChange={(newValue) => {
        setValue(newValue);
        if (dispatchState) {
          dispatchState([...newValue.map((item) => item.value)]);
        }
      }}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      label={label}
      hint={placeholder}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default CustomCreatableSelect;