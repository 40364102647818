import moment from "moment";

export const unitsColumns = [
  {
    dataField: "LAND_NUMBER",
    text: "Unit Number",
  },
  {
    dataField: "PROP_SUB_TYPE_EN",
    text: "Property Sub Type",
  },
  {
    dataField: "ACTUAL_AREA",
    text: "Property Size (sq.m)",
  },
  {
    dataField: "BALCONY_AREA",
    text: "Balcony Area",
  },
  {
    dataField: "COMMON_AREA",
    text: "Common Area",
  },
  {
    dataField: "ACTUAL_COMMON_AREA",
    text: "Actual Common Area",
  },
  {
    dataField: "FLOOR",
    text: "Floor",
  },
  {
    dataField: "ROOM_TYPE_EN",
    text: "Room Type",
  },
];

export const unitsFormFields = {
  tabId: "7",
  navLinkName: "Unit",
  endpoint: "units",
  sort: "BUILDING_NUMBER_ASC",
  fields: [
    {
      id: 3,
      name: "Area",
      fieldName: "P_AREA_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
      ]
    },
    {
      id: 5,
      name: "Zone",
      fieldName: "P_ZONE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Deira",
          value: "1",
        },
        {
          label: "Dubai",
          value: "2",
        },
      ]
    },
    {
      id: 3,
      name: "Is Free Hold?",
      fieldName: "P_IS_FREE_HOLD",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "2",
        },
      ]
    },
    {
      id: 3,
      name: "Is Lease Hold",
      fieldName: "P_IS_LEASE_HOLD",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "2",
        },
      ]
    },
    {
      id: 4,
      name: "Registration Type",
      fieldName: "P_IS_OFFPLAN",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Ready",
          value: "0",
        },
        {
          label: "Off Plan",
          value: "1",
        },
      ]
    },
  ],
};