import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

export default function SubOrdinateLeads({
  allowed, current, setCurrent
}) {
  const {
    t
  } = useTranslation();
  return (
    <Button
      color="primary"
      className={`btn btn-primary ${!allowed ? "d-none" : ""}`}
      onClick={()=> setCurrent(current === "own" ? "subordinates" : "own")}>
      {t("Switch to ")}{t(current === "own" ? "Subordinate Leads" : "Own Leads")}
    </Button>
  );
}