import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getProjects = async ({ payload }) => {
  const data = await axiosHelper.get(`/project?${qs.stringify(payload)}`);
  return data.result;
};

export const addProject = async ({ payload }) => {
  const data = await axiosHelper.post("/project", payload);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const editProject = async ({ payload }) => {
  const {
    id,
    ...values
  } = payload;
  const data = await axiosHelper.patch(`/project/${id}`, values);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const getDevelopers = async ({ payload }) => {
  const data = await axiosHelper.get(`/developer?${qs.stringify(payload)}`);
  return data.result;
};

export const addDeveloper = async ({ payload }) => {
  const data = await axiosHelper.post("/developer", payload);
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};

export const editDeveloper = async ({ payload }) => {
  const {
    name,
    id
  } = payload;
  const data = await axiosHelper.patch(`/developer/${id}`, {
    name,
  });
  if (data.isError) {
    throw new Error(data.message);
  }
  return data;
};