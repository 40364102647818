import React from "react";
import LeadStats from "./LeadStats";


const Dashboard = () => (
  <>
    <LeadStats />
  </>
);


export default Dashboard;