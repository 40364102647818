import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { editLeadAssign } from "store/teams/actions";
import CreatableSelect from "react-select/creatable";
import { fetchDictionaryStart } from "store/dictionary/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import SearchableCampaignSelect from "./SearchableCampaignSelect";
import SearchableAgentSelect from "./SearchableAgentSelect";

export default function EditLeadStructure(props) {
  const {
    toggle,
    typeId,
  } = props;

  const [countriesList, setCountriesList] = useState([]);

  const {
    countries,
    clearingCounter
  } = useSelector((state)=>({
    countries: state.dictionaryReducer.countries || [],
    clearingCounter: state.teamsReducer.clearingCounter || 0
  }));

  const dispatch = useDispatch();

  const handleGroupEdit = (e, v) => {
    if (typeId.type === "Team") {
      dispatch(editLeadAssign({
        ...v,
        typeId,
        type: "team",
        countries: countriesList.map((item)=>item.value),
      }));
    } else {
      dispatch(editLeadAssign({
        ...v,
        typeId,
        type: "campaign",
        agent: v.agent?.map((item)=>item.value),
        campaign: v.campaign?.value ? v.campaign?.value : v.campaign,
      }));
    }
  };

  useEffect(()=>{
    dispatch(fetchDictionaryStart());
  }, []);

  useEffect(()=>{
    if (countries) {
      const useCountries = typeId?.countries?.map((item)=>{
        const c = (countries || [])?.find((ct)=>ct._id == item);
        return {
          label: `${c?.en} | (${c?.ar})`,
          value: c
        };
      });
      setCountriesList(useCountries);
    }
  }, [typeId, countries]);

  return (
    <Modal isOpen={typeId} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        Edit Group
      </ModalHeader>
      <ModalBody>
        <AvForm
          className='p-4'
          onValidSubmit={(e, v) => {
            handleGroupEdit(e, v);
          }}
        >
          <Col md="12">
            <Label>{props.t("Type")}</Label>
            <div>
              <AvFieldSelect
                required
                name="type"
                options={[
                  {
                    label: "Campaign",
                    value: "campaign"
                  },
                  {
                    label: "Country",
                    value: "country"
                  },
                ]}
                isDisabled={true}
                value={
                  typeId?.type === "campaign" ? "campaign" : "country"
                }
                classNamePrefix="select2-selection"
                placeholder = "Choose Type"
              />
            </div>
          </Col>
          {
            typeId?.type === "facebook-campaigns" ? (<>
              <Row className="mb-3">
                <Col md="12">
                  <SearchableCampaignSelect
                    isRequired
                    value={{
                      label: typeId?.typeId?.campaignName,
                      value: typeId?.typeId
                    }}
                  />
                </Col>
                <Col md="12">
                  <SearchableAgentSelect
                    isRequired
                    value={typeId.structure.map((item)=>({
                      label: `${item.userId.firstName} ${item.userId.lastName}`,
                      value: item.userId._id
                    }))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Button color="primary" className="btn btn-primary" type="submit" >
                  {props.t("Update")}
                </Button>
              </Row>
            </>) : (
              <>
                <Row className="mb-3">
                  <Col md="12">
                    <Label>{props.t("Team")}</Label>
                    <div>
                      <Input
                        type="text"
                        name="team"
                        value={typeId.typeId.title}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
                {
                  typeId && <>{
                    typeId?.structure?.length === 0 ? <Alert color="info" className="mb-4">
                      <div>
                        <h5 className="alert-heading">No Team Members</h5>
                      </div>
                    </Alert> : <>
                      <Row className="mb-3" >
                        <Col md="6">
                          <Label>{props.t("Manager")}</Label>
                          <div>
                            <Input
                              type="text"
                              name="manager"
                              value={`${typeId?.typeId.managerId.firstName} ${typeId?.typeId.managerId.lastName}`}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <Label>{props.t("Priority")}</Label>
                          <div>
                            <AvField
                              type="number"
                              name={`manager.priority.${typeId?.typeId.managerId._id}`}
                              value={(typeId?.structure?.find((item)=> item.userId._id == typeId?.typeId.managerId._id)?.frequency)}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a priority",
                                },
                                min: {
                                  value: 0,
                                  errorMessage: "Please enter a valid priority",
                                },
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      {
                        typeId?.structure?.filter(m => (m.userId._id != typeId?.typeId.managerId._id)).map((member, index) => (
                          <Row className="mb-3" key={index}>
                            <Col md="6">
                              <Label>{props.t("Member")}</Label>
                              <div>
                                <Input
                                  type="text"
                                  name={`member.${member.userId._id}`}
                                  value={`${member.userId.firstName} ${member.userId.lastName}`}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <Label>{props.t("Priority")}</Label>
                              <div>
                                <AvField
                                  type="number"
                                  name={`member.priority.${member.userId._id}`}
                                  value={member.frequency}
                                  validate={{
                                    required: {
                                      value: true,
                                      message: "Please enter a priority",
                                    },
                                    min: {
                                      value: 1,
                                      message: "Please enter a valid priority",
                                    },
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        ))
                      }
                      <Row className="mb-3">
                        <Label>{props.t("Countries")}</Label>
                        <CreatableSelect
                          isMulti
                          isSearchable
                          isClearable
                          onChange={(newValue) => {
                            setCountriesList(newValue);
                          }}
                          label={props.t("Countries")}
                          hint={props.t("Countries")}
                          placeholder={props.t("Enter Countries")}
                          noOptionsMessage={() => props.t("No countries found")}
                          value={countriesList}
                          options={countries?.map((country) => ({
                            label: `${country.en} | ${country.ar}`,
                            value: country,
                          }))}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter a country",
                            },
                          }}
                        />
                      </Row>
                      <Row>
                        <Alert color="info" className="mb-4">
                          {props.t("Highest priority will be assigned first and based on the priority the number of leads will be assigned to them.")}
                        </Alert>
                      </Row>
                      <Row>
                        <Button color="primary" className="btn btn-primary" type="submit" disabled={!countriesList.length}>
                          {props.t("Update")}
                        </Button>
                      </Row>
                    </>
                  }</>
                }
              </>
            )
          }
        </AvForm>
      </ModalBody>
    </Modal>
  );
}
