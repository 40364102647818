import moment from "moment";

export const valuationsColumns = [
  {
    dataField: "PROPERTY_TOTAL_VALUE",
    text: "Property Total Value",
  },
  {
    dataField: "AREA_EN",
    text: "Area",
  },
  {
    dataField: "PROCEDURE_AREA",
    text: "Property Size (sq.m)",
  },
  {
    dataField: "PROCEDURE_YEAR",
    text: "Procedure Year",
  },
  {
    dataField: "PROCEDURE_NUMBER",
    text: "Procedure Number",
  },
  {
    dataField: "INSTANCE_DATE",
    text: "Transaction Date",
    formatter: (row) => moment(row.INSTANCE_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "ACTUAL_WORTH",
    text: "Amount",
  },
  {
    dataField: "ACTUAL_AREA",
    text: "Transaction Size (sq.m)",
  },
  {
    dataField: "PROPERTY_TYPE_EN",
    text: "Property Type",
  },
  {
    dataField: "PROP_SUB_TYPE_EN",
    text: "Property Sub Type",
  },
];

export const valuationsFormFields = {
  tabId: "4",
  navLinkName: "Valuations",
  endpoint: "valuations",
  sort: "PROJECT_NUMBER_ASC",
  fields: [
    {
      id: 1,
      name: "From Date",
      fieldName: "P_FROM_DATE",
      required: true,
      type: "date",
    },
    {
      id: 2,
      name: "To Date",
      fieldName: "P_TO_DATE",
      required: true,
      type: "date",
    },
    {
      id: 3,
      name: "Area",
      fieldName: "P_AREA_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
      ]
    },
    {
      id: 53,
      name: "Property Type",
      fieldName: "P_PROP_TYPE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Land",
          value: "1",
        },
        {
          label: "Building",
          value: "2",
        },
        {
          label: "Unit",
          value: "3",
        },
      ]
    },
  ]
};