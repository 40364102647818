import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import {
  useDispatch, connect,
} from "react-redux";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import "./index.css";

import TodoAdd from "components/Common/TodoAdd";
// import Loader from "components/Common/Loader";

import "@fullcalendar/bootstrap/main.css";
import {
  editTodoStart,
  fetchNotesStart,
  fetchRemindersStart,
  fetchTodosStart,
  fetchRemarksStart,
} from "store/actions";

//redux
import ViewTodo from "./ViewTodo";
import moment from "moment";
import { getReminderClassName } from "common/utils/getNoteType";
import { AvField, AvForm } from "availity-reactstrap-validation";
import SearchableAgentSelect from "pages/LeadAssign/SearchableAgentSelect";
import SearchableClientIbSelect from "components/Common/SearchableClientIbSelect";

const Calendar = (props) => {
  const { create: addTodos } = props.todosPermissions;
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState({
    startStr: "",
    endStr: "",
  });
  const [addModal, setAddReminderModal] = useState(false);
  const [todoObj, setTodoObj] = useState({
    note: "",
    timeEnd: "",
    type: "1",
    _id: "",
  });
  const [showViewModal, setShowViewModal] = useState(false);

  const [tasks, setTasks] = useState(true);
  const [notes, setNotes] = useState(true);
  const [reminders, setReminders] = useState(true);
  const [remarks, setRemarks] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dayList, setDayList] = useState([]);
  const [agent, setAgent] = useState([]);
  const [client, setClient] = useState(null);

  useEffect(() => {
    const data = [];
    reminders && data.push(...props.reminders);
    tasks     && data.push(...props.todos);
    notes     && data.push(...props.notes);
    remarks   && data.push(...props.remarks);
    setEvents(data.map(obj => {
      const calData = {
        ...obj,
        className: `text-white ${getReminderClassName(obj.type)}`,
        id: obj._id,
        title: obj.note,
        start: obj.createdAt,
        timeEnd: obj.createdAt,
      };
      if (obj.type === 1) {
        calData.start = obj.timeEnd;
        calData.timeEnd = obj.timeEnd;
      }
      return calData;
    }));
  }, [props.reminders, props.todos, props.notes, props.remarks, tasks, notes, reminders, remarks]);

  const handleDateClick = (arg) => {
    var date = new Date(arg["date"]);
    setSelectedDate(date);
  };

  const handleEventClick = (arg) => {
    setTodoObj({
      ...arg.event.extendedProps,
      _id: arg.event.id
    });
    setShowViewModal(true);
  };

  const dateRangeChange = (arg) => {
    if (dateRange.startStr !== arg.startStr || dateRange.endStr !== arg.endStr) {
      setDateRange({
        ...dateRange,
        ...arg,
      });
      const params = {
        page: 1,
        limit: 1000,
        start: arg.startStr,
        end: arg.endStr,
      };
      dispatch(fetchRemindersStart(params));
      dispatch(fetchTodosStart(params));
      dispatch(fetchNotesStart(params));
      dispatch(fetchRemarksStart(params));
    }
  };

  const handleDrop = (args) => {
    const todo = args.oldEvent._def.extendedProps;
    const end = moment(args.event.start);
    const now = moment();
    if (now.diff(end) > 0) {
      args.revert();
    } else
      dispatch(editTodoStart({
        id: todo._id,
        timeEnd: args.event.start,
        note: todo.note,
        time: todo.createAt,
        type: todo.type,
        status: todo.status
      }));
  };

  useEffect(() => {
    if (selectedDate) {
      const selectedDayTask = events.filter(obj => {
        const date = new Date(obj.start);
        if (agent?.length > 0) {
          return (!agent.includes(obj.agent));
        }
        return (date.getDate() === selectedDate.getDate() && date.getMonth() === selectedDate.getMonth() && date.getFullYear() === selectedDate.getFullYear());
      });
      setDayList(selectedDayTask);
    } else {
      setDayList([]);
    }
  }, [selectedDate, tasks, notes, reminders, remarks, agent]);

  useEffect(() => {
    if (agent?.length > 0) {
      const filteredEvents = events.filter(obj => {
        return agent.map(a => a.value).includes(obj.createdBy?._id);
      }
      );
      setEvents(filteredEvents);
    } else {
      const params = {
        page: 1,
        limit: 1000,
        start: dateRange.startStr,
        end: dateRange.endStr,
      };
      dispatch(fetchRemindersStart(params));
      dispatch(fetchTodosStart(params));
      dispatch(fetchNotesStart(params));
      dispatch(fetchRemarksStart(params));
    }
  }, [agent]);

  useEffect(() => {
    if (client?.value) {
      const filteredEvents = events.filter(obj => {
        return obj?.customerId?._id === client.value;
      }
      );
      setEvents(filteredEvents);
    } else {
      const params = {
        page: 1,
        limit: 1000,
        start: dateRange.startStr,
        end: dateRange.endStr,
      };
      dispatch(fetchRemindersStart(params));
      dispatch(fetchTodosStart(params));
      dispatch(fetchNotesStart(params));
      dispatch(fetchRemarksStart(params));
    }
  }, [client]);
  
  const getItemClass = (type) => {
    if (type === 0) {
      return "bg-success";
    } else if (type === 1) {
      return "bg-info";
    } else if (type === 2) {
      return "bg-warning";
    } else if (type === 3) {
      return "bg-danger";
    }
  };

  return (
    <React.Fragment>
      <TodoAdd
        show={addModal}
        hidenAddButton={true}
        onClose={() => { setAddReminderModal(false) }}
      />
      <ViewTodo
        show={showViewModal}
        data={todoObj}
        type={todoObj.type}
        onClose={() => { setShowViewModal(false) }}
      />
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Calendar")}</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col lg={3} sm={12}>
              <Card>
                <CardBody>
                  {
                    addTodos && <>
                      <Row>
                        <Button
                          color="primary"
                          className="btn-sm mb-2"
                          onClick={() => setAddReminderModal(true)}
                        >
                          <i className="bx bx-plus font-size-16 align-middle me-2"></i>
                          {props.t("Add Event")}
                        </Button>
                      </Row>
                      <hr />
                    </>
                  }
                  <Row className="mt-3">
                    <Col sm={6}>
                      <Button className={`cursor-pointer rounded-1 w-100 border-0 ${reminders ? "bg-info" : "bg-secondary"}`}
                        onClick={() => setReminders(!reminders)}
                      >Reminders</Button>
                    </Col>
                    <Col sm={6}>
                      <Button className={`cursor-pointer rounded-1 w-100 border-0 ${tasks ? "bg-success" : "bg-secondary"}`}
                        onClick={() => setTasks(!tasks)}
                      >Tasks</Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={6}>
                      <Button className={`cursor-pointer rounded-1 w-100 border-0 ${notes ? "bg-warning" : "bg-secondary"}`}
                        onClick={() => setNotes(!notes)}
                      >Notes</Button>
                    </Col>
                    <Col sm={6}>
                      <Button className={`cursor-pointer rounded-1 w-100 border-0 ${remarks ? "bg-danger" : "bg-secondary"}`}
                        onClick={() => setRemarks(!remarks)}
                      >Remarks</Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <AvForm>
                      <SearchableAgentSelect
                        name="agent"
                        label="Agent"
                        value={agent}
                        onChange={(e) => setAgent(e)}
                      />
                      <SearchableClientIbSelect 
                        name="client"
                        label="Client"
                        value={client}
                        onChange={(e) => setClient(e)}
                      />
                    </AvForm>
                  </Row>
                  <hr />
                  <Row className="mt-3" style={{
                    minHeight: "300px",
                  }}>
                    {
                      !selectedDate ? 
                        <Col sm={12}>
                          <h5 className="text-center">Select a date to view events</h5>
                        </Col>
                        :
                        <Col sm={12}>
                          {
                            selectedDate && <h5 className="text-center">{moment(selectedDate).format("DD MMM YYYY")}</h5>
                          }
                        </Col>  
                    }
                    {
                      selectedDate && dayList.length === 0 ? 
                        <h5 className="text-center">No events</h5>
                        :
                        dayList.map((item, index) => {
                          return (
                            <Col sm={12} key={index}>
                              <div className={`d-flex justify-content-between cursor-pointer rounded-1 p-2 w-100 border-0 shadow ${
                                getItemClass(item.type)
                              }`} onClick={
                                () => {
                                  setTodoObj(item);
                                  setShowViewModal(true);
                                }
                              }>
                                <h6>{item.title}</h6>
                                <h6>{moment(item.start).format("hh:mm A")}</h6>
                              </div>
                              <hr />
                            </Col>
                          );
                        }
                        )
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={9} sm={12}>
              <Card>
                <CardBody>
                  {/* legend */}
                  
                  <FullCalendar
                    plugins={[
                      BootstrapTheme,
                      dayGridPlugin,
                      interactionPlugin,
                    ]}
                    dayMaxEventRows={3}
                    moreLinkClick={(arg) => {
                      setSelectedDate(arg.date);
                    }}
                    slotDuration={"00:15:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,dayGridWeek,dayGridDay",
                    }}
                    events={events}
                    editable={true}
                    // droppable={true}
                    selectable={true}
                    dateClick={addTodos && handleDateClick}
                    eventClick={handleEventClick}
                    eventDrop={handleDrop}
                    datesSet={dateRangeChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  clientDetails: state.clientReducer.clientDetails || {},
  reminders: state.todosReducer.reminders && state.todosReducer.reminders.docs || [],
  todos: state.todosReducer.todos && state.todosReducer.todos.docs || [],
  notes: state.todosReducer.notes && state.todosReducer.notes.docs || [],
  remarks: state.todosReducer.remarks && state.todosReducer.remarks.docs || [],
  loading: state.todosReducer.loading,
  deletingClearCounter: state.todosReducer.deletingClearCounter,
  todosPermissions: state.Profile.todosPermissions || {},
});

export default connect(mapStateToProps, null)(withTranslation()(Calendar));