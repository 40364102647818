
export const FETCH_DEVELOPERS_START = "FETCH_DEVELOPERS_START";
export const FETCH_DEVELOPERS_SUCCESS = "FETCH_DEVELOPERS_SUCCESS";
export const FETCH_DEVELOPERS_ERROR = "FETCH_DEVELOPERS_ERROR";

export const ADD_DEVELOPERS_START = "ADD_DEVELOPERS_START";
export const ADD_DEVELOPERS_SUCCESS = "ADD_DEVELOPERS_SUCCESS";
export const ADD_DEVELOPERS_ERROR = "ADD_DEVELOPERS_ERROR";

export const EDIT_DEVELOPERS_START = "EDIT_DEVELOPERS_START";
export const EDIT_DEVELOPERS_SUCCESS = "EDIT_DEVELOPERS_SUCCESS";
export const EDIT_DEVELOPERS_ERROR = "EDIT_DEVELOPERS_ERROR";