import {
  call, put, takeEvery, delay
} from "redux-saga/effects";
// Login Redux States
import {
  ADD_DEVELOPERS_START,
  EDIT_DEVELOPERS_START,
  FETCH_DEVELOPERS_START,
} from "./actionTypes";

//Include Both Helper File with needed methods
import * as API from "../../apis/real_estate";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";
import {
  addDevelopersError,
  addDevelopersSuccess,
  editDevelopersError,
  editDevelopersSuccess,
  fetchDevelopersError,
  fetchDevelopersSuccess
} from "./actions";

function* fetchDevelopers(params) {
  try {
    const data = yield call(API.getDevelopers, params);
    yield put(fetchDevelopersSuccess(data));
  }
  catch (error) {
    yield put(fetchDevelopersError(error));
  }
}

function* addDeveloper(params) {
  try {
    const data = yield call(API.addDeveloper, params);
    const { result } = data;
    yield put(addDevelopersSuccess(result));
    yield put(showSuccessNotification("Developer added successfully!"));
  }
  catch (error) {
    yield put(addDevelopersError(error));
    yield put(showErrorNotification(error.message || "Something went wrong!"));
  }
}

function* editDeveloper(params) {
  try {
    const data = yield call(API.editDeveloper, params);
    const { result } = data;
    yield put(editDevelopersSuccess(result));
    yield put(showSuccessNotification("Developer edited successfully!"));
  }
  catch (error) {
    yield put(editDevelopersError(error));
    yield put(showErrorNotification(error.message || "Something went wrong!"));
  }
}

function* developerSaga() {
  yield takeEvery(FETCH_DEVELOPERS_START, fetchDevelopers);
  yield takeEvery(ADD_DEVELOPERS_START, addDeveloper);
  yield takeEvery(EDIT_DEVELOPERS_START, editDeveloper);
}

export default developerSaga;
