export const landColumns = [
  {
    dataField: "LAND_NUMBER",
    text: "Land Number",
  },
  {
    dataField: "LAND_SUB_NUMBER",
    text: "Land Sub Number",
  },
  {
    dataField: "PROP_SUB_TYPE_EN",
    text: "Property Sub Type",
  },
  {
    dataField: "LAND_TYPE_EN",
    text: "Land Type",
  },
  {
    dataField: "ACTUAL_AREA",
    text: "Property Size (sq.m)",
  },
  {
    dataField: "IS_OFFPLAN_EN",
    text: "Registration Type",
  },
  {
    dataField: "PRE_REGISTRATION_NUMBER",
    text: "Pre Registration Number",
  },
  {
    dataField: "IS_FREE_HOLD_EN",
    text: "Is Free Hold?",
  },
  {
    dataField: "DM_ZIP_CODE",
    text: "Zip Code",
  },
  {
    dataField: "MUNICIPALITY_NUMBER",
    text: "Municipality Number",
  },
  {
    dataField: "SEPARATED_FROM",
    text: "Separated From",
  },
  {
    dataField: "SEPARATED_REFERENCE",
    text: "Separated Reference",
  },
  {
    dataField: "MASTER_PROJECT_EN",
    text: "Master Project",
  },
  {
    dataField: "PROJECT_NUMBER",
    text: "Project Number",
  },
  {
    dataField: "PROJECT_EN",
    text: "Project Name",
  },
  {
    dataField: "AREA_EN",
    text: "Area",
  },
  {
    dataField: "ZONE_EN",
    text: "Zone",
  },
];

export const landFormFields = {
  tabId: "5",
  navLinkName: "Land",
  endpoint: "lands",
  sort: "LAND_NUMBER_ASC",
  fields: [
    {
      id: 53,
      name: "Project",
      fieldName: "P_PROJECT",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
      ]
    },
    {
      id: 2,
      name: "Land Type",
      fieldName: "P_LAND_TYPE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Residential",
          value: "1",
        },
        {
          label: "Agricultural",
          value: "2",
        },
        {
          label: "Industrial",
          value: "3",
        },
        {
          label: "Commercial",
          value: "4",
        },
        {
          label: "Public Facilities",
          value: "5",
        },
        {
          label: "Government Authority",
          value: "6",
        },
      ]
    },
    {
      id: 3,
      name: "Area",
      fieldName: "P_AREA_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
      ]
    },
    {
      id: 3,
      name: "Is Free Hold?",
      fieldName: "P_IS_FREE_HOLD",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ]
    },
    {
      id: 5,
      name: "Zone",
      fieldName: "P_ZONE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Deira",
          value: "1",
        },
        {
          label: "Dubai",
          value: "2",
        },
      ]
    },
  ]
};