import moment from "moment";

export const brokersColumns = [
  {
    dataField: "BROKER_NUMBER",
    text: "Broker Number",
  },
  {
    dataField: "BROKER_NUMBER",
    text: "Broker Name",
  },
  {
    dataField: "GENDER_EN",
    text: "Gender",
  },
  {
    dataField: "LICENSE_START_DATE",
    text: "License Start Date",
    formatter: (row) => moment(row.LICENSE_START_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "LICENSE_END_DATE",
    text: "License End Date",
    formatter: (row) => moment(row.LICENSE_END_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "WEBPAGE",
    text: "Website",
  },
  {
    dataField: "PHONE",
    text: "Phone",
  },
  {
    dataField: "FAX",
    text: "Fax",
  },
  {
    dataField: "REAL_ESTATE_NUMBER",
    text: "Real Estate Number",
  },
  {
    dataField: "REAL_ESTATE_EN",
    text: "Real Estate Name",
  },
];

export const brokersFormFields = {
  tabId: "8",
  navLinkName: "Broker",
  endpoint: "brokers",
  sort: "BROKER_NUMBER_ASC",
  fields: [
    {
      id: 333,
      name: "Gender",
      fieldName: "P_GENDER",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Male",
          value: "0",
        },
        {
          label: "Female",
          value: "1",
        },
      ]
    },
  ],
};