import {
  call, put, takeEvery
} from "redux-saga/effects";
// Login Redux States
import {
  ADD_PROJECT_START,
  EDIT_PROJECT_START,
  FETCH_PROJECT_START,
} from "./actionTypes";

//Include Both Helper File with needed methods
import * as API from "../../apis/real_estate";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";
import {
  addProjectError,
  addProjectSuccess,
  editProjectError,
  editProjectSuccess,
  fetchProjectError,
  fetchProjectSuccess
} from "./actions";

function* fetchProject(params) {
  try {
    const data = yield call(API.getProjects, params);
    yield put(fetchProjectSuccess(data));
  }
  catch (error) {
    yield put(fetchProjectError(error));
  }
}

function* addProject(params) {
  try {
    const data = yield call(API.addProject, params);
    const { result } = data;
    yield put(addProjectSuccess(result));
    yield put(showSuccessNotification("Project added successfully!"));
  }
  catch (error) {
    yield put(addProjectError(error));
    yield put(showErrorNotification(error.message || "Something went wrong!"));
  }
}

function* editProject(params) {
  try {
    const data = yield call(API.editProject, params);
    const { result } = data;
    yield put(editProjectSuccess(result));
    yield put(showSuccessNotification("Project edited successfully!"));
  }
  catch (error) {
    yield put(editProjectError(error));
    yield put(showErrorNotification(error.message || "Something went wrong!"));
  }
}

function* developerSaga() {
  yield takeEvery(FETCH_PROJECT_START, fetchProject);
  yield takeEvery(ADD_PROJECT_START, addProject);
  yield takeEvery(EDIT_PROJECT_START, editProject);
}

export default developerSaga;
