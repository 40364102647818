import React, { 
  useState 
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
import * as API from "apis/real_estate";
import { addProject } from "store/actions";

const loadClientsOptions = async (search, page) => {
  const payload = {
    page: page,
    limit: 30,
  };
  if (search) {
    payload.searchText = search;
  }
  const output = [];
  const data = await API
    .getDevelopers({
      payload: payload,
    });
  data?.docs?.map(function (item) {
    output.push({
      value: item,
      label: item.name,
    });
  });
  return {
    options: output,
    hasMore: data.hasNextPage,
  };
};

function AddProject(props){

  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [developer, setDeveloper] = useState(null);

  
  const loadPageOptions = async (q, prevOptions, { page }) => {
    const { options, hasMore } = await loadClientsOptions(q, page);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const toggle = () => setAddModal(!addModal);
  
  const toggleAddModal = () => {
    toggle();
  };

  const handleSubmit = (e, v) => {
    dispatch(addProject({
      ...v,
      developer: developer.value._id
    }));
  };
  return (
    <React.Fragment >
      <Button color="primary" className={"btn btn-primary "} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> {props.t("Add Project")}</Button>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add New Project")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={handleSubmit}
          >
            
            <Row className="mb-3">
              <Col md="12">
                <Label>{props.t("Project Name")}</Label>
                <div>
                  <AvField
                    name="name"
                    type="text"
                    required
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter a name",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md="12">
                <Label>{props.t("Project Size")}</Label>
                <div>
                  <AvField
                    name="size"
                    type="text"
                    required
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter a size",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md="12">
                <Label>{props.t("Developer")}</Label>
                <div>
                  <AsyncPaginate
                    additional={{ page: 1 }}
                    value={developer}
                    loadOptions={loadPageOptions}
                    placeholder="Select Developer Name ..."
                    onChange={(obj) => { setDeveloper(obj) }}
                    errorMessage="Please select Developer"
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Button color="primary" className="btn btn-primary" type="submit" disabled={!developer} >
                {props.t("Add")}
              </Button>
            </Row>
          </AvForm>
        </ModalBody> 
      </Modal>
    </React.Fragment>
  );
}

export default (withTranslation()(AddProject));