import React, { 
  useState 
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Input,
  Alert
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import "../SearchableInputStyles.scss";
import { withTranslation } from "react-i18next";
import { addDevelopers } from "store/actions";

function AddProject(props){

  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);

  const toggle = () => setAddModal(!addModal);

  
  const toggleAddModal = () => {
    toggle();
  };

  const handleAddGroup = (e, v) => {
    dispatch(addDevelopers({
      ...v,
    }));
  };

  return (
    <React.Fragment >
      <Button color="primary" className={"btn btn-primary "} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> {props.t("Add Developer")}</Button>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add New Developer")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleAddGroup(e, v);
            }}
          >
            
            <Row className="mb-3">
              <Col md="12">
                <Label>{props.t("Name")}</Label>
                <div>
                  <AvField
                    type="text"
                    name="name"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter a name",
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Button color="primary" className="btn btn-primary" type="submit">
                {props.t("Add")}
              </Button>
            </Row>
          </AvForm>
        </ModalBody> 
      </Modal>
    </React.Fragment>
  );
}

export default (withTranslation()(AddProject));