import React, { useEffect, useState } from "react";
import {
  useDispatch,
  connect,
  useSelector
} from "react-redux";
import {
  Row, Col, Button, CardBody, CardHeader, CardTitle, Card
} from "reactstrap";
import {
  AvForm, AvField
} from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";

// i18n 
import { withTranslation } from "react-i18next";
import {
  editClientDetails,
  removeInterestedProject,
} from "store/client/actions";
import { assignAgentStart } from "store/users/actions";
import Loader from "components/Common/Loader";
import { LANGUAGES } from "common/languages";
import { TITLES } from "common/data/dropdowns";
import _ from "lodash";
import { useParams } from "react-router-dom";
import TodoAdd from "components/Common/TodoAdd";
import { enableCrypto } from "config";
import SearchableAgentDropdown from "components/Common/SearchableAgentDropdown";
import CallStatusDropdown from "components/Common/CallStatusDropdown";
import SendEmail from "./QuickActions/sendEmail";
import AddClientProject from "./AddClientProject";
import FeatherIcon from "feather-icons-react";
import Notes from "../Notes";
import ConvertToLive from "./QuickActions/convertToLive";
import usePermissions from "routes/permissions";
import LeadType from "./LeadType";

function ClientDetails(props) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [convertToLiveModal, setConvertToLiveModal] = useState(false);
  const [clientData, setClientData] = useState(props.clientDetails);
  const [addNote, setAddNote] = useState(false);
  const [type, setType] = useState(false);
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const {
    todosPermissions,
    clientPermissions,
    leadsPermissions,
  } = usePermissions();

  const {
    _id = null,
    agentId = "",
    isAdmin = false,
  } = useSelector((state) => ({
    _id: state.Profile?._id ?? null,
    agentId: state.Profile?.agent?._id ?? "",
    isAdmin: state.Profile?.roleId?.isAdmin ?? false,
  }));

  const loadUpdatedClientDetailsHandler = (e, values) => {
    dispatch(editClientDetails({
      values,
      id: clientId
    }));
  };
  const assignAgent = (e, values) => {
    dispatch(assignAgentStart({
      ...values,
      clientId
    }));
  };

  // useEffect is used to set initial value once client details is loaded 
  // for agent, nationality and country so if the user clicks update without changing 
  // any of them it will load it's previous value and update all updated fields
  useEffect(() => {
  }, [props.clientDetails]);

  useEffect(() => {
    if (!props.showPortalAccessModal && deleteModal) {
      setDeleteModal(false);
    }
  }, [props.showPortalAccessModal]);

  useEffect(() => {
    setClientData(props.clientDetails);
  }, [props.clientDetails]);

  const {
    getSubordinates,
  } = leadsPermissions;

  return (
    <React.Fragment>
      {(props.clientProfileloading || props.usersLoading || props.dictLoading) &&
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      }
      {(!props.clientProfileloading && !props.usersLoading && !props.dictLoading) &&
        <div className="">
          <div className="container-fluid">
            <div>
              <Row>
                {/* input fields to the left side */}
                <Col md="9" sm="12" xs="12" className="mb-3">
                  {/* show the details only if this agent is assigned to client or is an admin or he is the manager of this client and has option to get
                  subordinates */}
                  { (!getSubordinates ||
                    (agentId === _id) ||
                    (isAdmin)
                  ) &&
                    <>
                      <Card className="overflow-visible">
                        <CardHeader className="d-flex flex-column gap-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <CardTitle>{props.t("General information")}</CardTitle>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <AvForm
                            onValidSubmit={(e, v) => {
                              loadUpdatedClientDetailsHandler(e, v);
                            }}
                          >
                            <div className="d-flex flex-column gap-4">
                              {/* first row */}
                              <Row>
                                <Col md="3">
                                  <div className="mt-2">
                                    <AvFieldSelect
                                      name="title"
                                      type="text"
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            title: e.value
                                          };
                                        });
                                      }}
                                      errorMessage={props.t("Title is required")}
                                      validate={{ required: { value: !props.clientDetails.isLead && true } }}
                                      value={props.clientDetails.title}
                                      label={props.t("Title")}
                                      options={TITLES.map((obj) => {
                                        return ({
                                          label: obj,
                                          value: obj
                                        });
                                      })}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mt-2">
                                    <AvField
                                      name="firstName"
                                      label={props.t("First name")}
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            firstName: e.target.value
                                          };
                                        });
                                      }}
                                      placeholder={props.t("Enter First Name")}
                                      type="text"
                                      errorMessage={props.t("Enter First Name")}
                                      validate={{ required: { value: !props.clientDetails.isLead && true } }}
                                      value={props.clientDetails.firstName}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mt-2">
                                    <AvField
                                      name="lastName"
                                      label={props.t("Last name")}
                                      placeholder={props.t("Enter Last Name")}
                                      type="text"
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            lastName: e.target.value
                                          };
                                        });
                                      }}
                                      errorMessage={props.t("Enter Last Name")}
                                      validate={{ required: { value: !props.clientDetails.isLead && true } }}
                                      value={props.clientDetails.lastName}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mt-2">
                                    <AvField
                                      name="phone"
                                      label={props.t("Phone")}
                                      placeholder={props.t("Enter Phone")}
                                      type="text"
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            phone: e.target.value
                                          };
                                        });
                                      }}
                                      errorMessage={props.t("Phone is required")}
                                      validate={
                                        {
                                          required: { value: !props.clientDetails.isLead && true },
                                          pattern: {
                                            // eslint-disable-next-line no-useless-escape
                                            value: "/^[\+][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im",
                                            errorMessage: "Phone number must be digits only with country key"
                                          }
                                        }
                                      }
                                      value={props.clientDetails.phone}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* second row */}
                              <Row>
                                <Col md="3">
                                  <div className="mt-2">
                                    <AvField
                                      name="experience.jobTitle"
                                      value={clientData.experience?.jobTitle ? clientData.experience.jobTitle : (
                                        clientData?.registerData?.jobTitle ? clientData?.registerData?.jobTitle : ""
                                      )}
                                      label="Job Title"
                                      placeholder="Enter Job Title"
                                      type="text"
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            experience: {
                                              ...state.experience,
                                              jobTitle: e.target.value
                                            }
                                          };
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="mt-2">
                                    <AvField
                                      name="email"
                                      label={props.t("Email")}
                                      placeholder={props.t("Email")}
                                      type="text"
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            email: e.target.value
                                          };
                                        });
                                      }}
                                      errorMessage={props.t("Email is required")}
                                      validate={{ required: { value: !props.clientDetails.isLead && true } }}
                                      value={props.clientDetails.email}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mt-2">
                                    <AvFieldSelect
                                      name="country"
                                      label={props.t("Country")}
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            country: e.value
                                          };
                                        });
                                      }}
                                      errorMessage={props.t("Country is required")}
                                      validate={{ required: { value: !props.clientDetails.isLead && true } }}
                                      value={props.clientDetails.country}
                                      options={props.countries.map((country) => {
                                        return ({
                                          label: `${country.en}`,
                                          value: country.en
                                        });
                                      })}
                                    />
                                  </div>
                                </Col>
                                {/* <Col md="3">
                                  <div className="mt-2">
                                    <AvFieldSelect
                                      name="gender"
                                      type="text"
                                      errorMessage={props.t("Gender is required")}
                                      validate={{ required: { value: !props.clientDetails.isLead && true } }}
                                      value={props.clientDetails.gender}
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            gender: e.value
                                          };
                                        });
                                      }}
                                      label={props.t("Gender")}
                                      options={[{
                                        value: "male",
                                        label: "Male"
                                      }, {
                                        value: "female",
                                        label: "Female"
                                      }]}
                                    />
                                  </div>
                                </Col> */}
                                <Col md="2">
                                  <div className="mt-2">
                                    <AvFieldSelect
                                      name="language"
                                      label={props.t("Language")}
                                      placeholder={props.t("Language")}
                                      type="text"
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            language: e.value
                                          };
                                        });
                                      }}
                                      errorMessage={props.t("Language is required")}
                                      validate={{ required: { value: !props.clientDetails.isLead && true } }}
                                      value={props.clientDetails.language}
                                      options={LANGUAGES}
                                    />
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="mt-2">
                                    <AvField
                                      name="source"
                                      label={props.t("Source")}
                                      placeholder={props.t("Source")}
                                      type="text"
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            source: e.target.value
                                          };
                                        });
                                      }}
                                      errorMessage={props.t("Source is required")}
                                      validate={{ required: { value: !props.clientDetails.isLead && true } }}
                                      value={props.clientDetails.source}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* fifth row */}
                              <Row>
                                {/* <Col md="3">
                                  <div className="mt-2">
                                    <AvFieldSelect
                                      name="workedInCrypto"
                                      label={props.t("Worked in Crypto ?")}
                                      placeholder={props.t("Worked in Crypto ?")}
                                      type="text"
                                      errorMessage={props.t("Worked in Crypto is required")}
                                      // validate={{ required: { value: true } }}
                                      value={props.clientDetails.workedInCrypto}
                                      options = {YESNO}
                                    />
                                  </div>
                                </Col> */}
                                {/* <Col md="3">
                                  <div className="mt-2">
                                    <AvFieldSelect
                                      name="politicallyExposed"
                                      label={props.t("Politically exposed ?")}
                                      placeholder={props.t("Politically exposed ?")}
                                      type="text"
                                      onChange={(e) => {
                                        setClientData((state) => {
                                          return {
                                            ...state,
                                            politicallyExposed: e.value
                                          };
                                        });
                                      }}
                                      errorMessage={props.t("Politically exposed is required")}
                                      // validate={{ required: { value: true } }}
                                      value={props.clientDetails.politicallyExposed}
                                      options={YESNO}
                                    />
                                  </div>
                                </Col> */}
                              </Row>
                              {/* sith row */}
                            </div>
                            <Row >
                              <Col md={3}>
                                <CallStatusDropdown
                                  label="Call Status"
                                  client={props?.clientDetails}
                                />
                              </Col>
                              <Col md={9} className="p-4">
                                {
                                  leadsPermissions?.update && <>
                                    <div className="d-flex justify-content-end">
                                      <Button
                                        disabled={props.updating || _.isEqual(clientData, props.clientDetails)}
                                        type="submit"
                                        color="primary"
                                      >
                                        {props.t("Update")}
                                      </Button>
                                    </div>
                                  </>
                                }
                              </Col>
                            </Row>
                          </AvForm>

                        </CardBody>
                      </Card>
                    </>
                  }
                  <Row>
                    <Card>
                      <CardHeader className="d-flex flex-column gap-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <CardTitle>{props.t("Interests")}</CardTitle>
                        </div>
                      </CardHeader>
                      <CardBody>
                        {
                          props.clientDetails?.interestedProjects?.map((project, index) => {
                            return (
                              <Row key={index}>
                                <Col md="10" sm="10" xs="10">
                                  <label>{props.t("Project")}: </label>
                                  <p>{project?.name}</p>
                                  <label>{props.t("Developer")}: </label>
                                  <p>{project?.developer?.name}</p>
                                </Col>
                                <Col md="2" sm="2" xs="2">
                                  <FeatherIcon
                                    icon="trash-2"
                                    className="cursor-pointer"
                                    size="18"
                                    color="red"
                                    onClick={() => {
                                      dispatch(removeInterestedProject({
                                        clientId: clientId,
                                        projectId: project._id
                                      }));
                                    }}
                                  />
                                </Col>
                                <hr />
                              </Row>
                            );
                          })
                        }
                        <Row className="d-flex justify-content-center">
                          <AddClientProject />
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
                {/* quick actions to the right side */}
                <Col md="3" sm="12" xs="12" className="mb-3">
                  <Card style={{
                    overflow: "visible",
                  }}>
                    <CardBody style={{ position: "relative" }}>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          assignAgent(e, v);
                        }}
                      >
                        <Row className="align-items-center">
                          <Col md={9}>
                            <SearchableAgentDropdown
                              isRequired={true}
                              clientData={props?.clientDetails || null}
                            />
                          </Col>
                          <Col md={3}>
                            {
                              (leadsPermissions?.update) && <>
                                <Button
                                  className="mt-3"
                                  disabled={props.updating}
                                  type="submit"
                                  color="primary"
                                >
                                  {props.t("Update")}
                                </Button>
                              </>
                            }
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                  {
                    leadsPermissions.getLeadType &&
                    <Card style={{
                      overflow: "visible",
                    }}>
                      <CardBody style={{ position: "relative" }}>
                        <Row className="align-items-center">
                          <Col md={9}>
                            <LeadType 
                              value={clientData?.leadType}
                              clientId={clientId}
                              update={leadsPermissions.updateLeadType}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  }
                  {
                    (!getSubordinates ||
                    (agentId === _id) ||
                    (isAdmin)) && 
                    <>
                      <Card>
                        <CardHeader className="d-flex flex-column gap-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <CardTitle>{props.t("Quick actions")}</CardTitle>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          {/* first action space */}
                          { clientPermissions.update && props.clientDetails?.isLead  && <CardBody className="quick-actions-card">
                            <p className="quick-actions-heading">Client</p>
                            <div className="btn-container">
                              {/* <PortalAccess clientDetails={props.clientDetails} clientId={clientId} />
                              <ResetPassword clientDetails={props.clientDetails} clientId={clientId} /> */}
                              <ConvertToLive clientId={clientId} isDisabled={!props.clientDetails?.isLead} open={convertToLiveModal} setOpen={setConvertToLiveModal} />
                            </div>
                          </CardBody>}
                          <CardBody className="quick-actions-card">
                            <p className="quick-actions-heading">Communication</p>
                            <div className="btn-container">
                              <SendEmail clientData={props.clientDetails} isLead={props.clientDetails.isLead} />
                            </div>
                          </CardBody>
                          <CardBody className="quick-actions-card">
                            <p className="quick-actions-heading">Misc</p>
                            <div className="btn-container">
                              <button type="button" className="btn btn-primary waves-effect waves-light w-100"
                                onClick={() => {
                                  setType(1);
                                  setAddNote(true);
                                }}
                              >
                                {props.t("Add Reminder")}
                              </button>
                              <button type="button" className="btn btn-primary waves-effect waves-light w-100"
                                onClick={() => {
                                  setType(0);
                                  setAddNote(true);
                                }}
                              >
                                {props.t("Add Task")}
                              </button>
                              <button type="button" className="btn btn-primary waves-effect waves-light w-100"
                                onClick={() => {
                                  setType(2);
                                  setAddNote(true);
                                }}
                              >
                                {props.t("Add Note")}
                              </button>
                              <button type="button" className="btn btn-primary waves-effect waves-light w-100"
                                onClick={() => {
                                  setType(3);
                                  setAddNote(true);
                                }}
                              >
                                {props.t("Add Remark")}
                              </button>
                              <TodoAdd
                                show={addNote}
                                selectedClient={clientData}
                                onClose={() => { setAddNote(false) }}
                                hidenAddButton={true}
                                type={type}
                              />
                              {/* <ClientAddBankAccountModal clientId={clientId} isLead={props.clientDetails.isLead} buttonText="Open Bank" /> */}
                              {/* <button type="button" disabled={props.clientDetails.isLead} className="btn btn-primary waves-effect waves-light w-100">
                                Print application
                              </button> */}
                            </div>
                          </CardBody>
                        </CardBody>
                      </Card>
                    </>
                  }
                  {
                    props.clientDetails.source === "FACEBOOK" && <>
                      <Card>
                        <CardHeader className="d-flex flex-column gap-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <CardTitle>{props.t("Facebook Ad Details")}</CardTitle>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="6" sm="12" xs="12">
                              <div className="d-flex flex-column gap-3">
                                <div>
                                  <label>{props.t("Campaign Name")}</label>
                                  <p>{props.clientDetails?.registerData?.campaign?.campaignName}</p>
                                </div>
                                <div>
                                  <label>{props.t("Ad Name")}</label>
                                  <p>{props.clientDetails?.registerData?.adData?.name}</p>
                                </div>
                                <div>
                                  <label>{props.t("Ad ID")}</label>
                                  <p>{props.clientDetails?.registerData?.adData?.id}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  }
                </Col>
                {/* <Col>
                  <Row>
                    <Col md="6" sm="12" xs="12">
                      <Card className="overflow-visible">
                        <CardHeader>
                          <CardTitle>Employment Info</CardTitle>
                        </CardHeader>

                        <CardBody>
                          <AvForm onValidSubmit={(e, v) => {
                            updateEmploymentInfo(e, v);
                          }}>
                            <Row>
                              <Col  >
                                <div>
                                  <AvFieldSelect
                                    name="employmentStatus"
                                    type="text"
                                    value={experience?.employmentStatus ? experience.employmentStatus : ""}
                                    errorMessage={props.t("Employment Status is required")}
                                    validate={{ required: { value: true } }}
                                    label={props.t("Employment Status")}
                                    options={employmentStatus.map((obj) => {
                                      return ({
                                        label: obj,
                                        value: obj
                                      });
                                    })}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <AvFieldSelect
                                    name="profession"
                                    label={props.t("Indusrtry")}
                                    placeholder={props.t("Industry is required")}
                                    type="text"
                                    value={experience?.profession ? experience.profession : ""}
                                    errorMessage={props.t("Industry is required")}
                                    validate={{ required: { value: true } }}
                                    options={professions.map((obj) => {
                                      return ({
                                        label: obj,
                                        value: obj
                                      });
                                    })}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mt-4">
                                  <AvField name="jobTitle"
                                    value={experience?.jobTitle ? experience.jobTitle : ""}
                                    label="Job Industry" />
                                </div>
                              </Col>
                              <Col>
                                <div className="mt-4">
                                  <AvField
                                    value={experience?.employer ? experience.employer : ""}
                                    name="employer"
                                    label="Employer" />
                                </div>
                              </Col>
                            </Row>
                            <div className="d-flex justify-content-end">
                              <div className="p-4">
                                <Button
                                  disabled={props.employmentInfoUpdating}
                                  type="submit"
                                  color="primary"
                                >
                                  {props.t("Update")}
                                </Button>
                              </div>
                            </div>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6" sm="12" xs="12">
                      <Card className="overflow-visible">
                        <CardHeader>
                          <CardTitle>Finanical Info</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <AvForm onValidSubmit={(e, v) => {
                            updateFinancialInfo(e, v);
                          }}>
                            <Row>
                              <Col >
                                <div className="mt-2">
                                  <AvFieldSelect
                                    name="annualIncome"
                                    type="text"
                                    errorMessage={props.t("Annual Income Status is required")}
                                    validate={{ required: { value: true } }}
                                    value={financialInfo?.annualIncome ? financialInfo.annualIncome : ""}
                                    label={props.t("Annual Income")}
                                    options={annualIncome.map((obj) => {
                                      return ({
                                        label: obj,
                                        value: obj
                                      });
                                    })}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mt-2">
                                  <AvFieldSelect
                                    name="sourceOfFunds"
                                    label={props.t("Soucre of Funds")}
                                    placeholder={props.t("Industry is required")}
                                    type="text"
                                    value={financialInfo?.sourceOfFunds ? financialInfo.sourceOfFunds : ""}
                                    errorMessage={props.t("Source of Funds is required")}
                                    validate={{ required: { value: true } }}
                                    options={sourceOfFunds.map((obj) => {
                                      return ({
                                        label: obj,
                                        value: obj
                                      });
                                    })}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <div className="mt-4">
                                <AvFieldSelect
                                  label="Worked in Financial?"
                                  name="workedInFinancial"
                                  value={financialInfo?.workedInFinancial ?? ""}
                                  errorMessage={props.t("This field is required")}
                                  validate={{ required: { value: true } }}
                                  options={YESNO}
                                />
                              </div>
                            </Row>
                            <div className="d-flex justify-content-end">
                              <div className="p-4">
                                <Button
                                  disabled={props.financialInfoUpdating}
                                  type="submit"
                                  color="primary"
                                >
                                  {props.t("Update")}
                                </Button>
                              </div>
                            </div>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col> */}
                {/* {declarations && declarations.length > 0 && <Row>
                  <Col md="6" sm="12" xs="12">
                    <Card>
                      <CardHeader className="d-flex flex-column gap-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <CardTitle>{props.t("Declarations")}</CardTitle>
                        </div>
                      </CardHeader>
                      <CardBody >
                        {declarations && declarations.length > 0 && declarations.map((declaration, index) => {
                          return <div className="d-flex gap-3 align-items-start" key={index}>
                            <input type="checkbox" className="d-block" checked={true} />
                            <p style={{ fontSize: "11px" }}>{parse(declaration)}</p>
                          </div>;
                        })}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>} */}
              </Row>
            </div>
          </div>
        </div>
      }
      {todosPermissions.get && <Notes />}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  clientProfileloading: state.clientReducer.clientProfileloading,
  updating: state.clientReducer.updating,
  convertToIb: state.clientReducer.convertToIb,
  convertToLive: state.clientReducer.convertToLive,
  clientDetails: state.clientReducer.clientDetails,
  usersDocs: state.usersReducer.salesAgent,
  usersLoading: state.usersReducer.loading,
  countries: state.dictionaryReducer.countries || [],
  dictLoading: state.dictionaryReducer.loading,
  markups: state.markupsReducer.markups || [],
  transactionFeeGroups: state.transactionFeeGroupReducer.transactionFeeGroups || [],
  feeGroups: state.feeGroupReducer.feeGroups || [],
  employmentInfoUpdating: state.clientReducer.employmentInfoUpdating,
  financialInfoUpdating: state.clientReducer.financialInfoUpdating,
  showPortalAccessModal: state.clientReducer.showPortalAccessModal
});

export default connect(mapStateToProps, null)(withTranslation()(ClientDetails));