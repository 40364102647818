const languageOptions = [
  {
    value: "en",
    label: "English"
  },
  {
    value: "ar",
    label: "Arabic"
  },
  {
    value: "fr",
    label: "French"
  },
  {
    value: "de",
    label: "German"
  },
  {
    value: "es",
    label: "Spanish"
  },
  {
    value: "it",
    label: "Italian"
  },
  {
    value: "pt",
    label: "Portuguese"
  },
  {
    value: "ru",
    label: "Russian"
  },
  // {
  //   value: "tr",
  //   label: "Turkish"
  // },
  // {
  //   value: "zh",
  //   label: "Chinese"
  // },
  // {
  //   value: "ja",
  //   label: "Japanese"
  // },
  // {
  //   value: "ko",
  //   label: "Korean"
  // },
  // {
  //   value: "id",
  //   label: "Indonesian"
  // },
];

const fields = [
  "firstName",
  "lastName",
  "email",
  "cpUrl",
  "crmUrl",
  "clientName",
  "customerSupportName",
];

export { languageOptions, fields };
