
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Label
} from "reactstrap";
import React, { 
  useState, 
  useEffect, 
} from "react";
import { 
  AvForm,
} from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import Loader from "components/Common/Loader";
import * as API from "apis/real_estate";
import { AsyncPaginate } from "react-select-async-paginate";
import { useParams } from "react-router";
import { addInterestedProject } from "store/client/actions";
import TableLoader from "components/Common/Loader";

const loadProjectOptions = async (search, page, projectIds) => {
  const payload = {
    page: page,
    limit: 30
  };
  if (search) {
    payload.searchText = search;
  }
  if (projectIds.length > 0) {
    payload._id = {
      $nin: projectIds
    };
  }
  const output = [];
  const data = await API
    .getProjects({
      payload: payload,
    });
  data?.docs?.map(function (item) {
    output.push({
      value: item,
      label: `${item.name} - ${item.developer.name}`,
    });
  });
  return {
    options: output,
    hasMore: data.hasNextPage,
  };
};

function AddClientProject(props) {
  const {
    clientId
  } = useParams();
  const dispatch = useDispatch();
  const [addModal, setAddUserModal] = useState(false);
  const [project, setProject] = useState(null);
  const {
    loading
  } = useSelector((state) => ({
    loading: state.clientReducer.projects.loading || false,
  }));
  
  const loadPageOptions = async (q, prevOptions, { page }) => {
    const { options, hasMore } = await loadProjectOptions(q, page, []);
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSubmit = () => {
    dispatch(addInterestedProject({
      projectId: project.value._id,
      clientId,
    }));
  };

  const toggleAddModal = () => {
    setAddUserModal(!addModal);
  };

  useEffect(() => {
    if (props.addClearingCounter > 0  && addModal) {
      setAddUserModal(false);
    }
  }, [props.addClearingCounter]);

  return (
    <React.Fragment>
      <Button
        color="primary"
        className="btn btn-primary"
        onClick={toggleAddModal}>
        <i className="bx bx-plus me-1"/> 
        {props.t("Add Interest Project")}
      </Button>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add Interest Project")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={handleSubmit}
          >
            <Row>
              <Col md="12">
                <Label>{props.t("Project")}</Label>
                <div>
                  <AsyncPaginate
                    additional={{ page: 1 }}
                    value={project}
                    loadOptions={loadPageOptions}
                    placeholder="Select Project Name ..."
                    onChange={(obj) => { setProject(obj) }}
                    errorMessage="Please select Project"
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
            </Row>
            <div className='text-center pt-3 p-2'>
              {
                props?.addLeadLoader
                  ?
                  <Loader />
                  :
                  <Button  type="submit" color="primary" className="" disabled={loading}>
                    {
                      loading ? <TableLoader /> : props.t("Submit")
                    }
                  </Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default (withTranslation()(AddClientProject));