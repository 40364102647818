
export const FETCH_PROJECT_START = "FETCH_PROJECT_START";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_ERROR = "FETCH_PROJECT_ERROR";

export const ADD_PROJECT_START = "ADD_PROJECT_START";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR";

export const EDIT_PROJECT_START = "EDIT_PROJECT_START";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";
export const EDIT_PROJECT_ERROR = "EDIT_PROJECT_ERROR";
