import React, {
  useEffect, useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { fetchIbParents } from "store/client/actions";
import { createSharedIbAgreement } from "store/actions";
import validatePositiveInputs from "helpers/validatePositiveInputs";
import {
  updateComissionProducts,
  updateRebateProducts,
  validate,
  validateCommMembers,
  validateMembers,
} from "./SharedIbModalValidator";
import useDeepCompareEffect from "use-deep-compare-effect";
import AvFieldSelect from "components/Common/AvFieldSelect";

const AddSharedIbModal = ({
  show,
  toggle,
  accountTypes: allAccountTypes,
  products,
  clientId,
  markups,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [productsModel, setProductsModel] = useState({});
  const [ibRebateTotals, setIBRebateTotals] = useState({});
  const [submissionFailure, setSubmissionFailure] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);

  const { parents } = useSelector(
    (state) => state.clientReducer?.clientDetails
  );

  useEffect(() => {
    clientId && dispatch(fetchIbParents({ clientId }));
  }, [clientId]);

  const { submitting } = useSelector((state) => state.ibAgreements);

  useEffect(() => {
    !submitting && show && toggle();
  }, [submitting]);

  useEffect(() => {
    setSubmissionFailure(false);
  }, []);

  useDeepCompareEffect(() => {
    if (show && allAccountTypes && allAccountTypes.length > 0) {
      const accountTypes = new Set();
      allAccountTypes.forEach((acc) => {
        // if (acc.title !== "Demo") {
        //   accountTypes.add(acc);
        // }
        if (acc?.type?.toLowerCase() !== "demo") {
          accountTypes.add(acc);
        }
      });
      setAccountTypes(Array.from(accountTypes));
    }
  }, [allAccountTypes, show]);

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered={true}
      scrollable={true}
      onClosed={() => {
        setSubmissionFailure(false);
      }}
    >
      <ModalHeader toggle={toggle} tag="h4">
        {t("New Shared IB Agreement")}
      </ModalHeader>
      <ModalBody>
        {submissionFailure ? (
          <div
            style={{
              display: "block",
              color: "white",
              marginBottom: 10,
              padding: 10,
              borderRadius: 5,
              backgroundColor: "#f65854",
            }}
            className="formValidationErrMessage"
          >
            {"Invalid values found. Please check the form again!"}
          </div>
        ) : null}
        <AvForm
          validationEvent={["onChange", "onInput", "onBlur"]}
          onValidSubmit={(e, v) => {
            const { title, totals, members } = v;
            setSubmissionFailure(false);
            dispatch(
              createSharedIbAgreement({
                title,
                totals,
                members,
              })
            );
          }}
          onInvalidSubmit={(e, v) => {
            setSubmissionFailure(true);
          }}
        >
          <AvField
            name={"title"}
            label={t("Agreement name")}
            className="mb-3"
            type="text"
            errorMessage={t("Required!")}
            validate={{ required: { value: true } }}
          />
          <Row className="justify-content-start">
            <Col md="3">{t("Type")}</Col>
            <Col>{t("Total Rebate")}</Col>
            <Col>{t("Total Comission")}</Col>
          </Row>
          {accountTypes?.map((type, accIdx) => (
            <Row key={type._id} className="my-1 align-items-center">
              <Col md="3">
                {type.title}
                <AvField
                  name={`totals[${accIdx}].accountTypeId`}
                  value={type._id}
                  type="hidden"
                />
              </Col>
              <Col>
                <AvField
                  name={`totals[${accIdx}].rebate`}
                  value={type.rebate}
                  bsSize="sm"
                  type="string"
                  errorMessage={t("Invalid value!")}
                  validate={{ required: { value: true } }}
                  onKeyPress={(e) => validatePositiveInputs(e)}
                />
              </Col>
              <Col>
                <AvField
                  name={`totals[${accIdx}].commission`}
                  bsSize="sm"
                  type="string"
                  errorMessage={t("Invalid value!")}
                  validate={{ required: { value: true } }}
                  onKeyPress={(e) => validatePositiveInputs(e)}
                />
              </Col>
            </Row>
          ))}
          <hr className="my-3" />
          <Accordion className="mb-3" alwaysOpen>
            {parents &&
              parents[0]
                .sort((a, b) => (a.level > b.level ? 1 : -1))
                .map((member, memberIdx) => (
                  <Accordion.Item key={member._id} eventKey={memberIdx}>
                    <Accordion.Header>
                      {member.firstName} {member.lastName}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion className="my-1" alwaysOpen>
                        {accountTypes && accountTypes?.map((type, accIdx) => (
                          <Accordion.Item key={type._id} eventKey={accIdx}>
                            <Accordion.Header>
                              <Row className="w-100 my-1 align-items-center my-0">
                                <Col md="3">
                                  {type.title}
                                  <AvField
                                    name={`members[${memberIdx}].values[${accIdx}].accountTypeId`}
                                    value={type._id}
                                    type="hidden"
                                  />
                                  <AvField
                                    name={`members[${memberIdx}].level`}
                                    value={String(member.level)}
                                    type="hidden"
                                  />
                                  <AvField
                                    name={`members[${memberIdx}].customerId`}
                                    value={member._id}
                                    type="hidden"
                                  />
                                </Col>
                                <Col>
                                  <AvField
                                    name={`members[${memberIdx}].values[${accIdx}].rebate`}
                                    bsSize="sm"
                                    type="string"
                                    errorMessage={t("Invalid value!")}
                                    validate={{
                                      async: (...rest) =>
                                        validate(...rest, memberIdx, accIdx, "rebate", ibRebateTotals),
                                    }}
                                    onKeyPress={(e) =>
                                      validatePositiveInputs(e)
                                    }
                                    onInput={(e) =>
                                      updateRebateProducts(
                                        e,
                                        memberIdx,
                                        accIdx,
                                        accountTypes,
                                        products,
                                        productsModel,
                                        ibRebateTotals,
                                        setIBRebateTotals,
                                        setProductsModel
                                      )
                                    }
                                  />
                                </Col>
                                <Col>
                                  <AvField
                                    name={`members[${memberIdx}].values[${accIdx}].commission`}
                                    bsSize="sm"
                                    type="string"
                                    errorMessage={t("Invalid value!")}
                                    validate={{
                                      async: (...rest) =>
                                        validate(...rest, memberIdx, accIdx, "commission", ibRebateTotals),
                                    }}
                                    onKeyPress={(e) =>
                                      validatePositiveInputs(e)
                                    }
                                    onInput={(e) =>
                                      updateComissionProducts(
                                        e,
                                        memberIdx,
                                        accIdx,
                                        accountTypes,
                                        products,
                                        productsModel,
                                        ibRebateTotals,
                                        setIBRebateTotals,
                                        setProductsModel
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </Accordion.Header>
                            <Accordion.Body>
                              {products.map((prod, prodIdx) => (
                                <Row
                                  key={prodIdx}
                                  className="my-1 align-items-center"
                                >
                                  <Col md="3">{prod}</Col>
                                  <Col>
                                    <AvField
                                      name={`members[${memberIdx}].values[${accIdx}].products.${prod}.rebate`}
                                      bsSize="sm"
                                      type="string"
                                      errorMessage={t("Invalid value!")}
                                      validate={{
                                        async: (...rest) =>
                                          validateMembers(
                                            ...rest,
                                            memberIdx,
                                            accIdx,
                                            "rebate",
                                            ibRebateTotals
                                          ),
                                      }}
                                      value={
                                        productsModel[`members[${memberIdx}]#values[${accIdx}]#products#${prod}#rebate`]
                                      }
                                      onKeyPress={(e) =>
                                        validatePositiveInputs(e)
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <AvField
                                      name={`members[${memberIdx}].values[${accIdx}].products.${prod}.commission`}
                                      bsSize="sm"
                                      type="string"
                                      errorMessage={t("Invalid value!")}
                                      validate={{
                                        async: (...rest) =>
                                          validateCommMembers(
                                            ...rest,
                                            memberIdx,
                                            accIdx,
                                            "commission",
                                            ibRebateTotals
                                          ),
                                      }}
                                      value={
                                        productsModel[`members[${memberIdx}]#values[${accIdx}]#products#${prod}#commission`]
                                      }
                                      onKeyPress={(e) =>
                                        validatePositiveInputs(e)
                                      }
                                    />
                                  </Col>
                                </Row>
                              ))}
                              <Row>
                                <Col md="3">Markup</Col>
                                <Col>
                                  <AvFieldSelect
                                    name={`members[${memberIdx}].values[${accIdx}].markup`}
                                    options={(markups || []).map((obj) => {
                                      return ({
                                        label: `${obj}`,
                                        value: obj
                                      });
                                    })}
                                  />
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
          </Accordion>
          <Button type="submit" disabled={submitting}>
            {t("Submit")}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default AddSharedIbModal;
