import {
  ADD_PROJECT_ERROR,
  ADD_PROJECT_START,
  ADD_PROJECT_SUCCESS,
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_START,
  EDIT_PROJECT_SUCCESS,
  FETCH_PROJECT_ERROR,
  FETCH_PROJECT_START,
  FETCH_PROJECT_SUCCESS,
} from "./actionTypes";


export const fetchProject = (params = {}) => {
  return {
    type: FETCH_PROJECT_START,
    payload: params
  };
};

export const fetchProjectSuccess = (data) => {
  return {
    type: FETCH_PROJECT_SUCCESS,
    payload: data
  };
};

export const fetchProjectError = (error) => {
  return {
    type: FETCH_PROJECT_ERROR,
    payload: { error }
  };
};

export const addProject = (params = {}) => {
  return {
    type: ADD_PROJECT_START,
    payload: params
  };
};

export const addProjectSuccess = (data) => {
  return {
    type: ADD_PROJECT_SUCCESS,
    payload: data
  };
};

export const addProjectError = (error) => {
  return {
    type: ADD_PROJECT_ERROR,
    payload: { error }
  };
};

export const editProject = (params = {}) => {
  return {
    type: EDIT_PROJECT_START,
    payload: params
  };
};

export const editProjectSuccess = (data) => {
  return {
    type: EDIT_PROJECT_SUCCESS,
    payload: data
  };
};

export const editProjectError = (error) => {
  return {
    type: EDIT_PROJECT_ERROR,
    payload: { error }
  };
};