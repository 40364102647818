import {
  FETCH_DEVELOPERS_START,
  FETCH_DEVELOPERS_SUCCESS,
  FETCH_DEVELOPERS_ERROR,
  ADD_DEVELOPERS_START,
  ADD_DEVELOPERS_SUCCESS,
  ADD_DEVELOPERS_ERROR,
  EDIT_DEVELOPERS_START,
  EDIT_DEVELOPERS_SUCCESS,
  EDIT_DEVELOPERS_ERROR,  
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  roles: [],
  clearingCounter: 0,
  docs: [],
  pagination: {},
};
const developerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVELOPERS_START:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case FETCH_DEVELOPERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        docs: action.payload.docs,
        pagination: {
          ...action.payload,
        },
      };
      break;
    case FETCH_DEVELOPERS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case ADD_DEVELOPERS_START:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case ADD_DEVELOPERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case ADD_DEVELOPERS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case EDIT_DEVELOPERS_START:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case EDIT_DEVELOPERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        clearingCounter: state.clearingCounter + 1,
      };
      break;
    case EDIT_DEVELOPERS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    default:
      state = { ...state };

  }
  return state;
};
export default developerReducer;