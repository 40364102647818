import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import {
  Card, CardBody, CardHeader, CardTitle
} from "reactstrap";
import TableLoader from "components/Common/Loader";
import {
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Table,
} from "react-super-responsive-table";
import CustomPagination from "components/Common/CustomPagination";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import FeatherIcon from "feather-icons-react";
import usePermissions from "routes/permissions";
import { fetchFacebookCampaignsStart } from "store/facebook-campaigns/actions";
import FetchCampaigns from "./FetchCampaigns";

function FacebookCampaigns() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sizePerPage, setSizePerPage] = useState(10);

  const {
    clearingCounter
  } = useSelector((state)=>({
    clearingCounter: state.teamsReducer.clearingCounter || 0
  }));

  const {
    facebookCampaignsPermissions,
  } = usePermissions();
  const {
    loading,
    docs,
    pagination,
  } = useSelector((state) => ({
    loading: state.facebookCampaignReducer.loading || false,
    docs: state.facebookCampaignReducer.docs || [],
    pagination: state.facebookCampaignReducer.pagination || {},
  }));

  const columns = [
    {
      text: "Campaign Id",
      dataField: "campaignId",
    },
    {
      text: "Campaign Name",
      dataField: "campaignName",
      sort: true,
    },
    {
      text: "Status",
      dataField: "status",
    },
    // {
    //   text: "Manager",
    //   dataField: "managerId",
    //   sort: true,
    //   formatter: (team) => (
    //     <>
    //       {team.teamId.managerId ? (
    //         <> {team.teamId.managerId.firstName + " " + team.teamId.managerId.lastName}</>
    //       ) : (
    //         " "
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   text: "Members",
    //   dataField: "members",
    //   sort: true,
    //   formatter: (team) => (
    //     <>
    //       {team.structure ? (
    //         <>
    //           {team.structure.length}
    //         </>
    //       ) : (
    //         "None"
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   dataField: "",
    //   isDummyField: true,
    //   editable: false,
    //   text: "Action",
    //   formatter: (team) => (
    //     <div className="text-center cursor-pointer">
    //       <div className={`text-success ${!parseInt(1) ? "d-none" : ""}`} to="#">
    //         <i
    //           className="mdi mdi-pencil font-size-18"
    //           id="edittooltip"
    //           onClick={() => setTeam(team)}
    //         ></i>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const loadCampaigns = (page, limit) => {
    dispatch(fetchFacebookCampaignsStart({
      page,
      limit,
    }));
  };
  
  useEffect(() => {
    loadCampaigns(1, sizePerPage);
  }, [clearingCounter]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("Facebook Campaigns")}</title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>{t("Facebook Campaigns")}</h2>
          <Card>
            <CardHeader className="d-flex justify-content-between  align-items-center">
              <CardTitle>
                Facebook Campaigns ({pagination?.totalDocs || 0})
                <FeatherIcon
                  icon="refresh-cw"
                  className="icon-lg ms-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => { loadCampaigns(1, sizePerPage) }}
                />
              </CardTitle>
              { facebookCampaignsPermissions.fetch && <>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <FetchCampaigns />
                </div>
              </>}
            </CardHeader>
            {/* {
              leadAssignPermissions.update && team && (
                <EditLeadStructure t={t} toggle={() => setTeam(null)} team={team} />
              )
            } */}
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table  table-hover "
                  >
                    <Thead className="text-center table-light" >
                      <Tr>
                        {columns.map((column, index) =>
                          <Th data-priority={index} key={index}>{column.text}</Th>
                        )}
                      </Tr>
                    </Thead>
                    <Tbody className="text-center">
                      {loading && <TableLoader colSpan={6} />}
                      {!loading && docs.map((row, rowIndex) =>
                        <Tr key={rowIndex}>
                          {columns.map((column, index) =>
                            <Td key={`${rowIndex}-${index}`}>
                              {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                            </Td>
                          )}
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                  <CustomPagination
                    docs={docs}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    onChange={loadCampaigns}
                    {...pagination}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
export default FacebookCampaigns;
