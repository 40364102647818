
import {
  call, put, takeEvery
} from "redux-saga/effects";
import { FETCH_FACEBOOK_FORMS_FIELDS, UPDATE_FACEBOOK_FORMS_FIELDS } from "./actionsType";
import { showErrorNotification, showSuccessNotification } from "store/notifications/actions";
import {
  fetchFacebookFormsFieldsFailed,
  fetchFacebookFormsFieldsSuccess,
  updateFacebookFormsFieldsFailed,
  updateFacebookFormsFieldsSuccess
} from "./actions";
import { loadFacebookFormsFields, updateFacebookFormsFields } from "apis/facebook-campaigns";


function* getFormFields(params) {
  try {
    const data = yield call(loadFacebookFormsFields, params);
    yield put(fetchFacebookFormsFieldsSuccess(data));
  } catch (error) {
    yield put(fetchFacebookFormsFieldsFailed(error));
  }
}

function* updateFormFields({ payload }) {
  try {
    const data = yield call(updateFacebookFormsFields, payload);
    yield put(showSuccessNotification("Updated fields successfully!"));
    yield put(updateFacebookFormsFieldsSuccess(data));
  }
  catch (error) {
    yield put(updateFacebookFormsFieldsFailed(error));
    yield put(showErrorNotification("Unable to update fields!"));
  }

}

function* facebookFieldsSaga() {
  yield takeEvery(FETCH_FACEBOOK_FORMS_FIELDS, getFormFields);
  yield takeEvery(UPDATE_FACEBOOK_FORMS_FIELDS, updateFormFields);
}

export default facebookFieldsSaga;