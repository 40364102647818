import moment from "moment";

export const rentsColumns = [
  {
    dataField: "CONTRACT_NUMBER",
    text: "Ejari Contract Number",
  },
  {
    dataField: "INSTANCE_DATE",
    text: "Registration Date Date",
    formatter: (row) => moment(row.INSTANCE_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "START_DATE",
    text: "Start Date",
    formatter: (row) => moment(row.START_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "END_DATE",
    text: "End Date",
    formatter: (row) => moment(row.END_DATE).format("DD/MM/YYYY")

  },
  {
    dataField: "VERSION_EN",
    text: "Version",
  },
  {
    dataField: "AREA_EN",
    text: "Area",
  },
  {
    dataField: "CONTRACT_AMOUNT",
    text: "Contract Amount",
  },
  {
    dataField: "ANNUAL_AMOUNT",
    text: "Annual Amount",
  },
  {
    dataField: "IS_FREE_HOLD_EN",
    text: "Is Free Hold?",
  },
  {
    dataField: "PROP_TYPE_EN",
    text: "Property Type",
  },
  {
    dataField: "PROP_SUB_TYPE_EN",
    text: "Property Sub Type",
  },
  {
    dataField: "ROOMS_EN",
    text: "Room(s)",
  },
  {
    dataField: "USAGE_EN",
    text: "Usage",
  },
  {
    dataField: "NEAREST_METRO_EN",
    text: "Nearest Metro",
  },
  {
    dataField: "NEAREST_MALL_EN",
    text: "Nearest Mall",
  },
  {
    dataField: "NEAREST_LANDMARK_EN",
    text: "Nearest Landmark",
  },
  {
    dataField: "PARKING",
    text: "Parking",
  },
  {
    dataField: "NEAREST_MALL_EN",
    text: "No Of Units",
  },
  {
    dataField: "MASTER_PROJECT_EN",
    text: "Master Project",
  },
  {
    dataField: "PROJECT_EN",
    text: "Project",
  },
];

export const rentsFormFields = {
  tabId: "2",
  navLinkName: "Rents",
  endpoint: "rents",
  sort: "CONTRACT_NUMBER_ASC",
  fields: [
    {
      id: 333,
      name: "Date",
      fieldName: "P_DATE_TYPE",
      required: false,
      type: "select",
      options: [
        {
          label: "Registration Date",
          value: "",
        },
        {
          label: "Start Date",
          value: "1",
        },
        {
          label: "End Date",
          value: "2",
        },
      ]
    },
    {
      id: 1,
      name: "From Date",
      fieldName: "P_FROM_DATE",
      required: true,
      type: "date",
    },
    {
      id: 2,
      name: "To Date",
      fieldName: "P_TO_DATE",
      required: true,
      type: "date",
    },
    {
      id: 33,
      name: "Version",
      fieldName: "P_VERSION",
      required: false,
      type: "select",
      sort: "CONTRACT_NUMBER_ASC",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "New",
          value: "1",
        },
        {
          label: "Renew",
          value: "2",
        },
      ]
    },
    {
      id: 3,
      name: "Is Free Hold?",
      fieldName: "P_IS_FREE_HOLD",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "2",
        },
      ]
    },
    {
      id: 3,
      name: "Area",
      fieldName: "P_AREA_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
      ]
    },
    {
      id: 3,
      name: "Usage",
      fieldName: "P_USAGE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Residential",
          value: "1",
        },
        {
          label: "Commercial",
          value: "2",
        },
        {
          label: "Other",
          value: "3",
        },
      ]
    },
    {
      id: 3,
      name: "Property Type",
      fieldName: "P_PROP_TYPE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Land",
          value: "1",
        },
        {
          label: "Building",
          value: "2",
        },
        {
          label: "Unit",
          value: "3",
        },
      ]
    },
  ]
};