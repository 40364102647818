import moment from "moment";

export const transColumns = [
  {
    dataField: "TRANSACTION_NUMBER",
    text: "Transaction Number",
  },
  {
    dataField: "INSTANCE_DATE",
    text: "Transaction Date",
    formatter: (row) => moment(row.INSTANCE_DATE).format("DD/MM/YYYY")
  },
  {
    dataField: "GROUP_EN",
    text: "Transaction Type",
  },
  {
    dataField: "PROCEDURE_EN",
    text: "Transaction Sub Type",
  },
  {
    dataField: "IS_OFFPLAN_EN",
    text: "Registration Type",
  },
  {
    dataField: "IS_FREE_HOLD_EN",
    text: "Is Free Hold?",
  },
  {
    dataField: "USAGE_EN",
    text: "Usage",
  },
  {
    dataField: "AREA_EN",
    text: "Area",
  },
  {
    dataField: "PROP_TYPE_EN",
    text: "Property Type",
  },
  {
    dataField: "TRANS_VALUE",
    text: "Amount",
  },
  {
    dataField: "ACTUAL_AREA",
    text: "Transaction Size (sq.m)",
  },
  {
    dataField: "PROCEDURE_AREA",
    text: "Property Size (sq.m)",
  },
  {
    dataField: "ROOMS_EN",
    text: "Room(s)",
  },
  {
    dataField: "NEAREST_METRO_EN",
    text: "Nearest Metro",
  },
  {
    dataField: "NEAREST_MALL_EN",
    text: "Nearest Mall",
  },
  {
    dataField: "TOTAL_BUYER",
    text: "No. of Buyer",
  },
  {
    dataField: "TOTAL_SELLER",
    text: "No. of Seller",
  },
  {
    dataField: "MASTER_PROJECT_EN",
    text: "Master Project",
  },
  {
    dataField: "PROJECT_EN",
    text: "Project",
  },
];

export const transFormFields = 
{
  tabId: "1",
  navLinkName: "Transactions",
  endpoint: "transactions",
  sort: "TRANSACTION_NUMBER_ASC",
  fields: [
    {
      id: 1,
      name: "From Date",
      fieldName: "P_FROM_DATE",
      required: true,
      type: "date",
    },
    {
      id: 2,
      name: "To Date",
      fieldName: "P_TO_DATE",
      required: true,
      type: "date",
    },
    {
      id: 3,
      name: "Transaction Type",
      fieldName: "P_GROUP_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Sales",
          value: "1",
        },
        {
          label: "Mortgages",
          value: "2",
        },
        {
          label: "Gifts",
          value: "3",
        },
      ]
    },
    {
      id: 4,
      name: "Registration Type",
      fieldName: "P_IS_OFFPLAN",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Ready",
          value: "0",
        },
        {
          label: "Off Plan",
          value: "1",
        },
      ]
    },
    {
      id: 3,
      name: "Is Free Hold?",
      fieldName: "P_IS_FREE_HOLD",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "2",
        },
      ]
    },
    {
      id: 3,
      name: "Area",
      fieldName: "P_AREA_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
      ]
    },
    {
      id: 3,
      name: "Usage",
      fieldName: "P_USAGE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Residential",
          value: "1",
        },
        {
          label: "Commercial",
          value: "2",
        },
        {
          label: "Other",
          value: "3",
        },
      ]
    },
    {
      id: 3,
      name: "Property Type",
      fieldName: "P_PROP_TYPE_ID",
      required: false,
      type: "select",
      options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Land",
          value: "1",
        },
        {
          label: "Building",
          value: "2",
        },
        {
          label: "Unit",
          value: "3",
        },
      ]
    },
  ]
};
